import React from 'react';
import HomeDialogMessage from './HomeDialogMessage';

function Home({ className, ...rest }) {
    return (
    <div>
        <HomeDialogMessage />
        <div id="Hero" className="header-1-v2">
        <div className="header-1-container">
            <div>
            <div className="header-2-white-fino">UNA PEQUEÑA GOTA,<br/>JUNTO A OTRA PEQUEÑA GOTA,<br/>JUNTO A OTRA PEQUEÑA GOTA<br/></div>
            </div>
            <h1 className="header-3-white">Somos la app de gestión financiera <br/>nacida con el espíritu de las cooperativas.</h1>
            <a href="https://play.google.com/store/apps/details?id=com.cabal.dimoapp&amp;hl=es-419" target="_blank" className="bt-descarga w-button">Descargá la app Dimo</a>
            
        </div>
        </div>
        <div className="section1">
        <h1 className="h1-dimo padd-bottom">Con Dimo vos tenés el control y podés moverte en cualquier entorno</h1>
        <div className="container-example w-container">
            <div className="w-row">
            <div className="centered w-col w-col-3">
                <a href="#" className="link-block-3 w-inline-block"><img src="images/ico-transferencia.svg" loading="lazy" alt="" className="icono" />
                <h2 className="h2-dimo">Transferencias<br/>y Giros</h2>
                <p className="paragraph-dimo padding-para">Con Dimo <strong>podés enviar y recibir dinero, cómo y adónde vos quieras</strong></p>
                </a>
            </div>
            <div className="centered w-col w-col-3">
                <a href="#" className="link-block-3 w-inline-block"><img src="images/ico-cuentas-cooperativas.svg" loading="lazy" alt="" className="icono"/>
                <h2 className="h2-dimo">Cuentas <br/>Cooperativas</h2>
                <p className="paragraph-dimo padding-para">Con Dimo podés <strong>administrar y controlar tus operaciones Cooperativas</strong></p>
                </a>
            </div>
            <div className="centered w-col w-col-3">
                <a href="#" className="link-block-3 w-inline-block"><img src="images/ico-cod-qr.svg" loading="lazy" alt="" className="icono"/>
                <h2 className="h2-dimo">Gestion de Efectivo</h2>
                <p className="paragraph-dimo padding-para"><strong>Pago en comercios</strong>, por medio de códigos QR</p>
                </a>
            </div>
            <div className="centered w-col w-col-3">
                <a href="#" className="link-block-3 w-inline-block"><img src="images/ico-pagos.svg" loading="lazy" alt="" className="icono"/>
                <h2 className="h2-dimo">Pagos de<br/>Servicios</h2>
                <p className="paragraph-dimo padding-para"><strong>Pago de Servicios</strong>, todos los servicios que necesites desde tu app Dimo</p>
                </a>
            </div>
            </div>
        </div>
        </div>

        <div className="section2 fondo_dimo1">
            <div className="container-example">
                <div className="w-row">
                    <div className="w-col w-col-6"><img src="images/collage-4-tarjetas-RGB-mediano.png" width="490" alt="" className="image-16" /></div>
                    <div className="padding-top-100 w-col w-col-6">
                        <h1 className="h1-dimo align-left text-white">Tarjetas prepagas Dimo</h1>
                        <p className="paragraph-dimo text-white">Dimo podés usarla sin tarjetas, pero para aprovechar al máximo tu experiencia, te brindamos las tarjetas Dimo, hay una tarjeta para cada estilo.<br />Obtené aquí tu tarjeta física o virtual Dimo.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Home;