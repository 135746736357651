import axios from 'axios';
import GAException from 'src/components/GAException';

import {
  ceibo_prm_sp_services_api_url as API_URL_CEIBO_PRMS_SP
}
from 'src/services/backendApiService';

export const prmTipo = {
    numero: "NUMERO",
    texto: "TEXTO",
    fecha: "FECHA",
}

class CeiboPrmSpService {

}

const ceiboPrmSpService = new CeiboPrmSpService();

export default ceiboPrmSpService;