//import axios from 'src/utils/axios';
import axios from '../utils/axios';

import { creditcard_service_api_url as API_URL } from 'src/services/backendApiService';

let BODY_HEADER = {}

const direct_api_tar = false;

if (direct_api_tar)
{
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: "102",
    idRequest: "123456",
    codigoEF: "csoto",
    canal: "102",
    idClienteEF: "prueba",
    ciCliente: "csoto"
  }
}
else
{
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion:"1.0.2",
    token:"prueba",
    user:"csoto"
  }
}

const CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
};

class LocationService {

  ciudades = () => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/Ciudades', { header: BODY_HEADER, data: {} }, CONFIG)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  paises = (Paises) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/Paises', { header: BODY_HEADER, data: Paises }, CONFIG)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

}

const locationService = new LocationService();

export default locationService;
