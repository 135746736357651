import React, { useEffect, useState } from "react";

import {
    IconButton,
    Box,
    Dialog,
    DialogTitle,
    Typography,
    makeStyles,
    DialogActions,
    Button,
}
from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import functionalitiesService, {funcionalidades} from 'src/services/functionalitiesService';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },

    imgModal: {
        maxHeight: "400px",
        height: "auto",
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));


function HomeDialogMessage({...props}) {

    const img_popup_url = "https://estatico.cabal.coop.py/public/dimo/flyers/main_page_modal.jpg";

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const mostrarMsgDialog = () => {
        functionalitiesService.funcionalidadHabilitada({codigo: funcionalidades.LANDPAGE_MSGMODAL.key})
        .then((response) => {
          
          if (response === true)
          {
            setOpen(true);
          }
        })
        .catch(()=> {
        })
    }

    useEffect(() => {
        mostrarMsgDialog();
    }, [])


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>
            <DialogTitle id="simple-dialog-title" style={{display:"none"}}>
                
                <Typography  variant="h3" color="primary">
                    {'DIMO'}
                </Typography>
                
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>    
                    <CloseIcon />
                </IconButton>
                
            </DialogTitle>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>    
                <CloseIcon />
            </IconButton>

            <Box style={{padding:"2px"}}
            >
                <img src={img_popup_url} className={classes.imgModal}></img>
            </Box>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HomeDialogMessage;