import entityService from 'src/services/entityService';

export const OBTENER_CIERRE_EMISOR_REQUEST = '@account/obtenerCierreEmisor-request';
export const OBTENER_CIERRE_EMISOR_SUCCESS = '@account/obtenerCierreEmisor-request';
export const OBTENER_CIERRE_EMISOR_FAILURE = '@account/obtenerCierreEmisor-request';

export const CONSULTA_EMISOR_TARJETA_REQUEST = '@account/consultaEmisorTarjeta-request';
export const CONSULTA_EMISOR_TARJETA_SUCCESS = '@account/consultaEmisorTarjeta-request';
export const CONSULTA_EMISOR_TARJETA_FAILURE = '@account/consultaEmisorTarjeta-request';

export function obtenerCierreEmisor(obtenerCierreEmisorReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: OBTENER_CIERRE_EMISOR_REQUEST });

      const obtenerCierreEmisor = await entityService.obtenerCierreEmisor(obtenerCierreEmisorReq);

      dispatch({
        type: OBTENER_CIERRE_EMISOR_SUCCESS,
        payload: {
          obtenerCierreEmisor
        }
      });
    } catch (error) {
      dispatch({ type: OBTENER_CIERRE_EMISOR_FAILURE });
      throw error;
    }
  };
}

export function consultaEmisorTarjeta(consultaEmisorTarjetaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTA_EMISOR_TARJETA_REQUEST });

      const consultaEmisorTarjeta = await entityService.consultaEmisorTarjeta(consultaEmisorTarjetaReq);

      dispatch({
        type: CONSULTA_EMISOR_TARJETA_SUCCESS,
        payload: {
          consultaEmisorTarjeta
        }
      });
    } catch (error) {
      dispatch({ type: CONSULTA_EMISOR_TARJETA_FAILURE });
      throw error;
    }
  };
}
