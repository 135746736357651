import prepaidCardService from 'src/services/prepaidCardService';

export const MARCAR_LIMITE_MENSUAL_TARJETA_REQUEST = '@account/marcarLimiteMensualTarjeta-request';
export const MARCAR_LIMITE_MENSUAL_TARJETA_SUCCESS = '@account/marcarLimiteMensualTarjeta-request';
export const MARCAR_LIMITE_MENSUAL_TARJETA_FAILURE = '@account/marcarLimiteMensualTarjeta-request';

export const ESTADO_CUENTA_POR_PERIODO_REQUEST = '@account/estadoCuentaPorPeriodo-request';
export const ESTADO_CUENTA_POR_PERIODO_SUCCESS = '@account/estadoCuentaPorPeriodo-request';
export const ESTADO_CUENTA_POR_PERIODO_FAILURE = '@account/estadoCuentaPorPeriodo-request';

export const DESCARGAR_ESTADO_CUENTA_REQUEST = '@account/descargarEstadoCuenta-request';
export const DESCARGAR_ESTADO_CUENTA_SUCCESS = '@account/descargarEstadoCuenta-request';
export const DESCARGAR_ESTADO_CUENTA_FAILURE = '@account/descargarEstadoCuenta-request';

export const TARJETAS_DIMO_REQUEST = '@account/tarjetasDimo-request';
export const TARJETAS_DIMO_SUCCESS = '@account/tarjetasDimo-request';
export const TARJETAS_DIMO_FAILURE = '@account/tarjetasDimo-request';

export const SEGUIMIENTO_REPOSICION_DIMO_REQUEST = '@account/seguimientoReposicionDimo-request';
export const SEGUIMIENTO_REPOSICION_DIMO_SUCCESS = '@account/seguimientoReposicionDimo-request';
export const SEGUIMIENTO_REPOSICION_DIMO_FAILURE = '@account/seguimientoReposicionDimo-request';

export const SEGUIMIENTO_IMPRESION_TARJETA_DIMO_REQUEST = '@account/seguimientoImpresionTarjetaDimo-request';
export const SEGUIMIENTO_IMPRESION_TARJETA_DIMO_SUCCESS = '@account/seguimientoImpresionTarjetaDimo-request';
export const SEGUIMIENTO_IMPRESION_TARJETA_DIMO_FAILURE = '@account/seguimientoImpresionTarjetaDimo-request';

export const REPOSICION_TARJETA_DIMO_REQUEST = '@account/reposicionTarjetaDimo-request';
export const REPOSICION_TARJETA_DIMO_SUCCESS = '@account/reposicionTarjetaDimo-request';
export const REPOSICION_TARJETA_DIMO_FAILURE = '@account/reposicionTarjetaDimo-request';

export const REGISTRO_RELACIONAR_DIMO_PREEMITIDA_REQUEST = '@account/registroRelacionarDimoPreemitida-request';
export const REGISTRO_RELACIONAR_DIMO_PREEMITIDA_SUCCESS = '@account/registroRelacionarDimoPreemitida-request';
export const REGISTRO_RELACIONAR_DIMO_PREEMITIDA_FAILURE = '@account/registroRelacionarDimoPreemitida-request';

export const REGISTRO_DIMO_PREEMITIDA_REQUEST = '@account/registroDimoPreemitida-request';
export const REGISTRO_DIMO_PREEMITIDA_SUCCESS = '@account/registroDimoPreemitida-request';
export const REGISTRO_DIMO_PREEMITIDA_FAILURE = '@account/registroDimoPreemitida-request';

export const REGISTRO_DIMO_REQUEST = '@account/registroDimo-request';
export const REGISTRO_DIMO_SUCCESS = '@account/registroDimo-request';
export const REGISTRO_DIMO_FAILURE = '@account/registroDimo-request';

export const PEDIDO_IMPRESION_TARJETA_DIMO_REQUEST = '@account/pedidoImpresionTarjetaDimo-request';
export const PEDIDO_IMPRESION_TARJETA_DIMO_SUCCESS = '@account/pedidoImpresionTarjetaDimo-request';
export const PEDIDO_IMPRESION_TARJETA_DIMO_FAILURE = '@account/pedidoImpresionTarjetaDimo-request';

export const MOVIMIENTOS_DIMO_REQUEST = '@account/movimientosDimo-request';
export const MOVIMIENTOS_DIMO_SUCCESS = '@account/movimientosDimo-request';
export const MOVIMIENTOS_DIMO_FAILURE = '@account/movimientosDimo-request';

export const MODIFICACION_PIN_DIMO_REQUEST = '@account/modificacionPinDimo-request';
export const MODIFICACION_PIN_DIMO_SUCCESS = '@account/modificacionPinDimo-request';
export const MODIFICACION_PIN_DIMO_FAILURE = '@account/modificacionPinDimo-request';

export const LIMITES_TRANSACCIONES_DIMO_REQUEST = '@account/limitesTransaccionesDimo-request';
export const LIMITES_TRANSACCIONES_DIMO_SUCCESS = '@account/limitesTransaccionesDimo-request';
export const LIMITES_TRANSACCIONES_DIMO_FAILURE = '@account/limitesTransaccionesDimo-request';

export const GENERACION_PIN_DIMO_REQUEST = '@account/generacionPinDimo-request';
export const GENERACION_PIN_DIMO_SUCCESS = '@account/generacionPinDimo-request';
export const GENERACION_PIN_DIMO_FAILURE = '@account/generacionPinDimo-request';

export const COSTOS_OPERACIONALES_DIMO_REQUEST = '@account/costosOperacionalesDimo-request';
export const COSTOS_OPERACIONALES_DIMO_SUCCESS = '@account/costosOperacionalesDimo-request';
export const COSTOS_OPERACIONALES_DIMO_FAILURE = '@account/costosOperacionalesDimo-request';

export const CONSULTA_SALDO_DIMO_REQUEST = '@account/consultaSaldoDimo-request';
export const CONSULTA_SALDO_DIMO_SUCCESS = '@account/consultaSaldoDimo-request';
export const CONSULTA_SALDO_DIMO_FAILURE = '@account/consultaSaldoDimo-request';

export const CLIENTE_DIMO_REQUEST = '@account/clienteDimo-request';
export const CLIENTE_DIMO_SUCCESS = '@account/clienteDimo-request';
export const CLIENTE_DIMO_FAILURE = '@account/clienteDimo-request';

export const CONSULTAR_PIN_DIMO_REQUEST = '@account/consultarPinDimo-request';
export const CONSULTAR_PIN_DIMO_SUCCESS = '@account/consultarPinDimo-request';
export const CONSULTAR_PIN_DIMO_FAILURE = '@account/consultarPinDimo-request';

export const CARGOS_APLICADOS_DIMO_REQUEST = '@account/cargosAplicadosDimo-request';
export const CARGOS_APLICADOS_DIMO_SUCCESS = '@account/cargosAplicadosDimo-request';
export const CARGOS_APLICADOS_DIMO_FAILURE = '@account/cargosAplicadosDimo-request';

export const CARGO_APLICADO_DIMO_REQUEST = '@account/cargoAplicadoDimo-request';
export const CARGO_APLICADO_DIMO_SUCCESS = '@account/cargoAplicadoDimo-request';
export const CARGO_APLICADO_DIMO_FAILURE = '@account/cargoAplicadoDimo-request';

export const BLOQUEO_DESBLOQUEO_DIMO_REQUEST = '@account/bloqueoDesbloqueoDimo-request';
export const BLOQUEO_DESBLOQUEO_DIMO_SUCCESS = '@account/bloqueoDesbloqueoDimo-request';
export const BLOQUEO_DESBLOQUEO_DIMO_FAILURE = '@account/bloqueoDesbloqueoDimo-request';

export const AVISO_VIAJE_DIMO_REQUEST = '@account/avisoViajeDimo-request';
export const AVISO_VIAJE_DIMO_SUCCESS = '@account/avisoViajeDimo-request';
export const AVISO_VIAJE_DIMO_FAILURE = '@account/avisoViajeDimo-request';

export function marcarLimiteMensualTarjeta(marcarLimiteMensualTarjetaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: MARCAR_LIMITE_MENSUAL_TARJETA_REQUEST });

      const marcarLimiteMensualTarjeta = await prepaidCardService.marcarLimiteMensualTarjeta(marcarLimiteMensualTarjetaReq);

      dispatch({
        type: MARCAR_LIMITE_MENSUAL_TARJETA_SUCCESS,
        payload: {
          marcarLimiteMensualTarjeta
        }
      });
    } catch (error) {

      dispatch({ type: MARCAR_LIMITE_MENSUAL_TARJETA_FAILURE });
      throw error;
    }
  };
}

export function estadoCuentaPorPeriodo(estadoCuentaPorPeriodoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: ESTADO_CUENTA_POR_PERIODO_REQUEST });

      const estadoCuentaPorPeriodo = await prepaidCardService.estadoCuentaPorPeriodo(estadoCuentaPorPeriodoReq);

      dispatch({
        type: ESTADO_CUENTA_POR_PERIODO_SUCCESS,
        payload: {
          estadoCuentaPorPeriodo
        }
      });
    } catch (error) {

      dispatch({ type: ESTADO_CUENTA_POR_PERIODO_FAILURE });
      throw error;
    }
  };
}

export function descargarEstadoCuenta(descargarEstadoCuentaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: DESCARGAR_ESTADO_CUENTA_REQUEST });

      const descargarEstadoCuenta = await prepaidCardService.descargarEstadoCuenta(descargarEstadoCuentaReq);

      dispatch({
        type: DESCARGAR_ESTADO_CUENTA_SUCCESS,
        payload: {
          descargarEstadoCuenta
        }
      });
    } catch (error) {

      dispatch({ type: DESCARGAR_ESTADO_CUENTA_FAILURE });
      throw error;
    }
  };
}

export function tarjetasDimo(tarjetasDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: TARJETAS_DIMO_REQUEST });

      const tarjetasDimo = await prepaidCardService.tarjetasDimo(tarjetasDimoReq);

      dispatch({
        type: TARJETAS_DIMO_SUCCESS,
        payload: {
          tarjetasDimo
        }
      });
    } catch (error) {

      dispatch({ type: TARJETAS_DIMO_FAILURE });
      throw error;
    }
  };
}

export function seguimientoReposicionDimo(seguimientoReposicionDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_REPOSICION_DIMO_REQUEST });

      const seguimientoReposicionDimo = await prepaidCardService.seguimientoReposicionDimo(seguimientoReposicionDimoReq);

      dispatch({
        type: SEGUIMIENTO_REPOSICION_DIMO_SUCCESS,
        payload: {
          seguimientoReposicionDimo
        }
      });
    } catch (error) {

      dispatch({ type: SEGUIMIENTO_REPOSICION_DIMO_FAILURE });
      throw error;
    }
  };
}

export function seguimientoImpresionTarjetaDimo(seguimientoImpresionTarjetaDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_IMPRESION_TARJETA_DIMO_REQUEST });

      const seguimientoImpresionTarjetaDimo = await prepaidCardService.seguimientoImpresionTarjetaDimo(seguimientoImpresionTarjetaDimoReq);

      dispatch({
        type: SEGUIMIENTO_IMPRESION_TARJETA_DIMO_SUCCESS,
        payload: {
          seguimientoImpresionTarjetaDimo
        }
      });
    } catch (error) {

      dispatch({ type: SEGUIMIENTO_IMPRESION_TARJETA_DIMO_FAILURE });
      throw error;
    }
  };
}

export function reposicionTarjetaDimo(reposicionTarjetaDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: REPOSICION_TARJETA_DIMO_REQUEST });

      const reposicionTarjetaDimo = await prepaidCardService.reposicionTarjetaDimo(reposicionTarjetaDimoReq);

      dispatch({
        type: REPOSICION_TARJETA_DIMO_SUCCESS,
        payload: {
          reposicionTarjetaDimo
        }
      });
    } catch (error) {

      dispatch({ type: REPOSICION_TARJETA_DIMO_FAILURE });
      throw error;
    }
  };
}

export function registroRelacionarDimoPreemitida(registroRelacionarDimoPreemitidaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTRO_RELACIONAR_DIMO_PREEMITIDA_REQUEST });

      const registroRelacionarDimoPreemitida = await prepaidCardService.registroRelacionarDimoPreemitida(registroRelacionarDimoPreemitidaReq);

      dispatch({
        type: REGISTRO_RELACIONAR_DIMO_PREEMITIDA_SUCCESS,
        payload: {
          registroRelacionarDimoPreemitida
        }
      });
    } catch (error) {

      dispatch({ type: REGISTRO_RELACIONAR_DIMO_PREEMITIDA_FAILURE });
      throw error;
    }
  };
}

export function registroDimoPreemitida(registroDimoPreemitidaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTRO_DIMO_PREEMITIDA_REQUEST });

      const registroDimoPreemitida = await prepaidCardService.registroDimoPreemitida(registroDimoPreemitidaReq);

      dispatch({
        type: REGISTRO_DIMO_PREEMITIDA_SUCCESS,
        payload: {
          registroDimoPreemitida
        }
      });
    } catch (error) {

      dispatch({ type: REGISTRO_DIMO_PREEMITIDA_FAILURE });
      throw error;
    }
  };
}

export function registroDimo(registroDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTRO_DIMO_REQUEST });

      const registroDimo = await prepaidCardService.registroDimo(registroDimoReq);

      dispatch({
        type: REGISTRO_DIMO_SUCCESS,
        payload: {
          registroDimo
        }
      });
    } catch (error) {

      dispatch({ type: REGISTRO_DIMO_FAILURE });
      throw error;
    }
  };
}

export function pedidoImpresionTarjetaDimo(pedidoImpresionTarjetaDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: PEDIDO_IMPRESION_TARJETA_DIMO_REQUEST });

      const pedidoImpresionTarjetaDimo = await prepaidCardService.pedidoImpresionTarjetaDimo(pedidoImpresionTarjetaDimoReq);

      dispatch({
        type: PEDIDO_IMPRESION_TARJETA_DIMO_SUCCESS,
        payload: {
          pedidoImpresionTarjetaDimo
        }
      });
    } catch (error) {

      dispatch({ type: PEDIDO_IMPRESION_TARJETA_DIMO_FAILURE });
      throw error;
    }
  };
}

export function movimientosDimo(movimientosDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: MOVIMIENTOS_DIMO_REQUEST });

      const movimientosDimo = await prepaidCardService.movimientosDimo(movimientosDimoReq);

      dispatch({
        type: MOVIMIENTOS_DIMO_SUCCESS,
        payload: {
          movimientosDimo
        }
      });
    } catch (error) {

      dispatch({ type: MOVIMIENTOS_DIMO_FAILURE });
      throw error;
    }
  };
}

export function modificacionPinDimo(modificacionPinDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: MODIFICACION_PIN_DIMO_REQUEST });

      const modificacionPinDimo = await prepaidCardService.modificacionPinDimo(modificacionPinDimoReq);

      dispatch({
        type: MODIFICACION_PIN_DIMO_SUCCESS,
        payload: {
          modificacionPinDimo
        }
      });
    } catch (error) {

      dispatch({ type: MODIFICACION_PIN_DIMO_FAILURE });
      throw error;
    }
  };
}

export function limitesTransaccionesDimo(limitesTransaccionesDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: LIMITES_TRANSACCIONES_DIMO_REQUEST });

      const limitesTransaccionesDimo = await prepaidCardService.limitesTransaccionesDimo(limitesTransaccionesDimoReq);

      dispatch({
        type: LIMITES_TRANSACCIONES_DIMO_SUCCESS,
        payload: {
          limitesTransaccionesDimo
        }
      });
    } catch (error) {

      dispatch({ type: LIMITES_TRANSACCIONES_DIMO_FAILURE });
      throw error;
    }
  };
}

export function generacionPinDimo(generacionPinDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: GENERACION_PIN_DIMO_REQUEST });

      const generacionPinDimo = await prepaidCardService.generacionPinDimo(generacionPinDimoReq);

      dispatch({
        type: GENERACION_PIN_DIMO_SUCCESS,
        payload: {
          generacionPinDimo
        }
      });
    } catch (error) {

      dispatch({ type: GENERACION_PIN_DIMO_FAILURE });
      throw error;
    }
  };
}

export function costosOperacionalesDimo(costosOperacionalesDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: COSTOS_OPERACIONALES_DIMO_REQUEST });

      const costosOperacionalesDimo = await prepaidCardService.costosOperacionalesDimo(costosOperacionalesDimoReq);

      dispatch({
        type: COSTOS_OPERACIONALES_DIMO_SUCCESS,
        payload: {
          costosOperacionalesDimo
        }
      });
    } catch (error) {

      dispatch({ type: COSTOS_OPERACIONALES_DIMO_FAILURE });
      throw error;
    }
  };
}

export function consultaSaldoDimo(consultaSaldoDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTA_SALDO_DIMO_REQUEST });

      const consultaSaldoDimo = await prepaidCardService.consultaSaldoDimo(consultaSaldoDimoReq);

      dispatch({
        type: CONSULTA_SALDO_DIMO_SUCCESS,
        payload: {
          consultaSaldoDimo
        }
      });
    } catch (error) {

      dispatch({ type: CONSULTA_SALDO_DIMO_FAILURE });
      throw error;
    }
  };
}

export function clienteDimo(clienteDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CLIENTE_DIMO_REQUEST });

      const clienteDimo = await prepaidCardService.clienteDimo(clienteDimoReq);

      dispatch({
        type: CLIENTE_DIMO_SUCCESS,
        payload: {
          clienteDimo
        }
      });
    } catch (error) {

      dispatch({ type: CLIENTE_DIMO_FAILURE });
      throw error;
    }
  };
}

export function consultarPinDimo(consultarPinDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTAR_PIN_DIMO_REQUEST });

      const consultarPinDimo = await prepaidCardService.consultarPinDimo(consultarPinDimoReq);

      dispatch({
        type: CONSULTAR_PIN_DIMO_SUCCESS,
        payload: {
          consultarPinDimo
        }
      });
    } catch (error) {

      dispatch({ type: CONSULTAR_PIN_DIMO_FAILURE });
      throw error;
    }
  };
}

export function cargosAplicadosDimo(cargosAplicadosDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CARGOS_APLICADOS_DIMO_REQUEST });

      const cargosAplicadosDimo = await prepaidCardService.cargosAplicadosDimo(cargosAplicadosDimoReq);

      dispatch({
        type: CARGOS_APLICADOS_DIMO_SUCCESS,
        payload: {
          cargosAplicadosDimo
        }
      });
    } catch (error) {

      dispatch({ type: CARGOS_APLICADOS_DIMO_FAILURE });
      throw error;
    }
  };
}

export function cargoAplicadoDimo(cargoAplicadoDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CARGO_APLICADO_DIMO_REQUEST });

      const cargoAplicadoDimo = await prepaidCardService.cargoAplicadoDimo(cargoAplicadoDimoReq);

      dispatch({
        type: CARGO_APLICADO_DIMO_SUCCESS,
        payload: {
          cargoAplicadoDimo
        }
      });
    } catch (error) {

      dispatch({ type: CARGO_APLICADO_DIMO_FAILURE });
      throw error;
    }
  };
}

export function bloqueoDesbloqueoDimo(bloqueoDesbloqueoDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOQUEO_DESBLOQUEO_DIMO_REQUEST });

      const bloqueoDesbloqueoDimo = await prepaidCardService.bloqueoDesbloqueoDimo(bloqueoDesbloqueoDimoReq);

      dispatch({
        type: BLOQUEO_DESBLOQUEO_DIMO_SUCCESS,
        payload: {
          bloqueoDesbloqueoDimo
        }
      });
    } catch (error) {

      dispatch({ type: BLOQUEO_DESBLOQUEO_DIMO_FAILURE });
      throw error;
    }
  };
}

export function avisoViajeDimo(avisoViajeDimoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: AVISO_VIAJE_DIMO_REQUEST });

      const avisoViajeDimo = await prepaidCardService.avisoViajeDimo(avisoViajeDimoReq);

      dispatch({
        type: AVISO_VIAJE_DIMO_SUCCESS,
        payload: {
          avisoViajeDimo
        }
      });
    } catch (error) {

      dispatch({ type: AVISO_VIAJE_DIMO_FAILURE });
      throw error;
    }
  };
}
