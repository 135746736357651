import React from 'react';

import GAException from 'src/components/GAException';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 128,
    paddingBottom: 128
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function Testimonials({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
          className={classes.title}
        >
          &quot;Con DIMO pude transferir el salario a mis empleados sin
          <br />
          salir de casa.&quot;
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            src={process.env.REACT_APP_STATIC_URL + "/static/home/sabino.jpg"}
            imgProps={{loading:"lazy"}}
          />
            <Box ml={2}>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                Sabino Cañete
              <Typography
                  color="textSecondary"
                  display="inline"
                  component="span"
                >
                  , CEO Pago Facil
              </Typography>
              </Typography>
            </Box>
        </Box>
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
        className: PropTypes.string
};

export default Testimonials;
