import creditCardService from 'src/services/creditCardService';

export const SEGUIMIENTO_AUMENTO_LINEA_REQUEST = '@account/seguimientoAumentoLinea-request';
export const SEGUIMIENTO_AUMENTO_LINEA_SUCCESS = '@account/seguimientoAumentoLinea-request';
export const SEGUIMIENTO_AUMENTO_LINEA_FAILURE = '@account/seguimientoAumentoLinea-request';

export const CONSULTA_EXTRACTO_REQUEST = '@account/consultaExtracto-request';
export const CONSULTA_EXTRACTO_SUCCESS = '@account/consultaExtracto-request';
export const CONSULTA_EXTRACTO_FAILURE = '@account/consultaExtracto-request';

export const DESCARGAR_EXTRACTO_REQUEST = '@account/descargarExtracto-request';
export const DESCARGAR_EXTRACTO_SUCCESS = '@account/descargarExtracto-request';
export const DESCARGAR_EXTRACTO_FAILURE = '@account/descargarExtracto-request';

export const TARJETAS_CREDITO_REQUEST = '@account/tarjetasCredito-request';
export const TARJETAS_CREDITO_SUCCESS = '@account/tarjetasCredito-request';
export const TARJETAS_CREDITO_FAILURE = '@account/tarjetasCredito-request';

export const SEGUIMIENTO_REIMPRESION_T_C_REQUEST = '@account/seguimientoReimpresionTC-request';
export const SEGUIMIENTO_REIMPRESION_T_C_SUCCESS = '@account/seguimientoReimpresionTC-request';
export const SEGUIMIENTO_REIMPRESION_T_C_FAILURE = '@account/seguimientoReimpresionTC-request';

export const SEGUIMIENTO_CAMBIO_CICLO_T_C_REQUEST = '@account/seguimientoCambioCicloTC-request';
export const SEGUIMIENTO_CAMBIO_CICLO_T_C_SUCCESS = '@account/seguimientoCambioCicloTC-request';
export const SEGUIMIENTO_CAMBIO_CICLO_T_C_FAILURE = '@account/seguimientoCambioCicloTC-request';

export const SEGUIMIENTO_ADICIONAL_T_C_REQUEST = '@account/seguimientoAdicionalTC-request';
export const SEGUIMIENTO_ADICIONAL_T_C_SUCCESS = '@account/seguimientoAdicionalTC-request';
export const SEGUIMIENTO_ADICIONAL_T_C_FAILURE = '@account/seguimientoAdicionalTC-request';

export const REIMPRESION_T_C_REQUEST = '@account/reimpresionTC-request';
export const REIMPRESION_T_C_SUCCESS = '@account/reimpresionTC-request';
export const REIMPRESION_T_C_FAILURE = '@account/reimpresionTC-request';

export const MOVIMIENTOS_T_C_REQUEST = '@account/movimientosTC-request';
export const MOVIMIENTOS_T_C_SUCCESS = '@account/movimientosTC-request';
export const MOVIMIENTOS_T_C_FAILURE = '@account/movimientosTC-request';

export const CONSULTA_SALDO_T_C_REQUEST = '@account/consultaSaldoTC-request';
export const CONSULTA_SALDO_T_C_SUCCESS = '@account/consultaSaldoTC-request';
export const CONSULTA_SALDO_T_C_FAILURE = '@account/consultaSaldoTC-request';

export const CONSULTA_SALDO_ADICIONAL_T_C_REQUEST = '@account/consultaSaldoAdicionalTC-request';
export const CONSULTA_SALDO_ADICIONAL_T_C_SUCCESS = '@account/consultaSaldoAdicionalTC-request';
export const CONSULTA_SALDO_ADICIONAL_T_C_FAILURE = '@account/consultaSaldoAdicionalTC-request';

export const CAMBIO_CICLO_T_C_REQUEST = '@account/cambioCicloTC-request';
export const CAMBIO_CICLO_T_C_SUCCESS = '@account/cambioCicloTC-request';
export const CAMBIO_CICLO_T_C_FAILURE = '@account/cambioCicloTC-request';

export const BLOQUEO_DESBLOQUEO_T_C_REQUEST = '@account/bloqueoDesbloqueoTC-request';
export const BLOQUEO_DESBLOQUEO_T_C_SUCCESS = '@account/bloqueoDesbloqueoTC-request';
export const BLOQUEO_DESBLOQUEO_T_C_FAILURE = '@account/bloqueoDesbloqueoTC-request';

export const AVISO_VIAJE_T_C_REQUEST = '@account/avisoViajeTC-request';
export const AVISO_VIAJE_T_C_SUCCESS = '@account/avisoViajeTC-request';
export const AVISO_VIAJE_T_C_FAILURE = '@account/avisoViajeTC-request';

export const AUMENTO_LINEA_T_C_REQUEST = '@account/aumentoLineaTC-request';
export const AUMENTO_LINEA_T_C_SUCCESS = '@account/aumentoLineaTC-request';
export const AUMENTO_LINEA_T_C_FAILURE = '@account/aumentoLineaTC-request';

export const ADICIONAL_T_C_REQUEST = '@account/adicionalTC-request';
export const ADICIONAL_T_C_SUCCESS = '@account/adicionalTC-request';
export const ADICIONAL_T_C_FAILURE = '@account/adicionalTC-request';

export function seguimientoAumentoLinea(seguimientoAumentoLineaReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_AUMENTO_LINEA_REQUEST });

      const seguimientoAumentoLinea = await creditCardService.seguimientoAumentoLinea(seguimientoAumentoLineaReq);

      dispatch({
        type: SEGUIMIENTO_AUMENTO_LINEA_SUCCESS,
        payload: {
          seguimientoAumentoLinea
        }
      });
    } catch (error) {
      dispatch({ type: SEGUIMIENTO_AUMENTO_LINEA_FAILURE });
      throw error;
    }
  };
}

export function consultaExtracto(consultaExtractoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTA_EXTRACTO_REQUEST });

      const consultaExtracto = await creditCardService.consultaExtracto(consultaExtractoReq);

      dispatch({
        type: CONSULTA_EXTRACTO_SUCCESS,
        payload: {
          consultaExtracto
        }
      });
    } catch (error) {
      dispatch({ type: CONSULTA_EXTRACTO_FAILURE });
      throw error;
    }
  };
}

export function descargarExtracto(descargarExtractoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: DESCARGAR_EXTRACTO_REQUEST });

      const descargarExtracto = await creditCardService.descargarExtracto(descargarExtractoReq);

      dispatch({
        type: DESCARGAR_EXTRACTO_SUCCESS,
        payload: {
          descargarExtracto
        }
      });
    } catch (error) {
      dispatch({ type: DESCARGAR_EXTRACTO_FAILURE });
      throw error;
    }
  };
}

export function tarjetasCredito(tarjetasCreditoReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: TARJETAS_CREDITO_REQUEST });

      const tarjetasCredito = await creditCardService.tarjetasCredito(tarjetasCreditoReq);

      dispatch({
        type: TARJETAS_CREDITO_SUCCESS,
        payload: {
          tarjetasCredito
        }
      });
    } catch (error) {
      dispatch({ type: TARJETAS_CREDITO_FAILURE });
      throw error;
    }
  };
}

export function seguimientoReimpresionTC(seguimientoReimpresionTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_REIMPRESION_T_C_REQUEST });

      const seguimientoReimpresionTC = await creditCardService.seguimientoReimpresionTC(seguimientoReimpresionTCReq);

      dispatch({
        type: SEGUIMIENTO_REIMPRESION_T_C_SUCCESS,
        payload: {
          seguimientoReimpresionTC
        }
      });
    } catch (error) {
      dispatch({ type: SEGUIMIENTO_REIMPRESION_T_C_FAILURE });
      throw error;
    }
  };
}

export function seguimientoCambioCicloTC(seguimientoCambioCicloTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_CAMBIO_CICLO_T_C_REQUEST });

      const seguimientoCambioCicloTC = await creditCardService.seguimientoCambioCicloTC(seguimientoCambioCicloTCReq);

      dispatch({
        type: SEGUIMIENTO_CAMBIO_CICLO_T_C_SUCCESS,
        payload: {
          seguimientoCambioCicloTC
        }
      });
    } catch (error) {
      dispatch({ type: SEGUIMIENTO_CAMBIO_CICLO_T_C_FAILURE });
      throw error;
    }
  };
}

export function seguimientoAdicionalTC(seguimientoAdicionalTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: SEGUIMIENTO_ADICIONAL_T_C_REQUEST });

      const seguimientoAdicionalTC = await creditCardService.seguimientoAdicionalTC(seguimientoAdicionalTCReq);

      dispatch({
        type: SEGUIMIENTO_ADICIONAL_T_C_SUCCESS,
        payload: {
          seguimientoAdicionalTC
        }
      });
    } catch (error) {
      dispatch({ type: SEGUIMIENTO_ADICIONAL_T_C_FAILURE });
      throw error;
    }
  };
}

export function reimpresionTC(reimpresionTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: REIMPRESION_T_C_REQUEST });

      const reimpresionTC = await creditCardService.reimpresionTC(reimpresionTCReq);

      dispatch({
        type: REIMPRESION_T_C_SUCCESS,
        payload: {
          reimpresionTC
        }
      });
    } catch (error) {
      dispatch({ type: REIMPRESION_T_C_FAILURE });
      throw error;
    }
  };
}

export function movimientosTC(movimientosTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: MOVIMIENTOS_T_C_REQUEST });

      const movimientosTC = await creditCardService.movimientosTC(movimientosTCReq);

      dispatch({
        type: MOVIMIENTOS_T_C_SUCCESS,
        payload: {
          movimientosTC
        }
      });
    } catch (error) {
      dispatch({ type: MOVIMIENTOS_T_C_FAILURE });
      throw error;
    }
  };
}

export function consultaSaldoTC(consultaSaldoTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTA_SALDO_T_C_REQUEST });

      const consultaSaldoTC = await creditCardService.consultaSaldoTC(consultaSaldoTCReq);

      dispatch({
        type: CONSULTA_SALDO_T_C_SUCCESS,
        payload: {
          consultaSaldoTC
        }
      });
    } catch (error) {
      dispatch({ type: CONSULTA_SALDO_T_C_FAILURE });
      throw error;
    }
  };
}

export function consultaSaldoAdicionalTC(consultaSaldoAdicionalTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTA_SALDO_ADICIONAL_T_C_REQUEST });

      const consultaSaldoAdicionalTC = await creditCardService.consultaSaldoAdicionalTC(consultaSaldoAdicionalTCReq);

      dispatch({
        type: CONSULTA_SALDO_ADICIONAL_T_C_SUCCESS,
        payload: {
          consultaSaldoAdicionalTC
        }
      });
    } catch (error) {
      dispatch({ type: CONSULTA_SALDO_ADICIONAL_T_C_FAILURE });
      throw error;
    }
  };
}

export function cambioCicloTC(cambioCicloTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CAMBIO_CICLO_T_C_REQUEST });

      const cambioCicloTC = await creditCardService.cambioCicloTC(cambioCicloTCReq);

      dispatch({
        type: CAMBIO_CICLO_T_C_SUCCESS,
        payload: {
          cambioCicloTC
        }
      });
    } catch (error) {
      dispatch({ type: CAMBIO_CICLO_T_C_FAILURE });
      throw error;
    }
  };
}

export function bloqueoDesbloqueoTC(bloqueoDesbloqueoTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOQUEO_DESBLOQUEO_T_C_REQUEST });

      const bloqueoDesbloqueoTC = await creditCardService.bloqueoDesbloqueoTC(bloqueoDesbloqueoTCReq);

      dispatch({
        type: BLOQUEO_DESBLOQUEO_T_C_SUCCESS,
        payload: {
          bloqueoDesbloqueoTC
        }
      });
    } catch (error) {
      dispatch({ type: BLOQUEO_DESBLOQUEO_T_C_FAILURE });
      throw error;
    }
  };
}

export function avisoViajeTC(avisoViajeTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: AVISO_VIAJE_T_C_REQUEST });

      const avisoViajeTC = await creditCardService.avisoViajeTC(avisoViajeTCReq);

      dispatch({
        type: AVISO_VIAJE_T_C_SUCCESS,
        payload: {
          avisoViajeTC
        }
      });
    } catch (error) {
      dispatch({ type: AVISO_VIAJE_T_C_FAILURE });
      throw error;
    }
  };
}

export function aumentoLineaTC(aumentoLineaTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: AUMENTO_LINEA_T_C_REQUEST });

      const aumentoLineaTC = await creditCardService.aumentoLineaTC(aumentoLineaTCReq);

      dispatch({
        type: AUMENTO_LINEA_T_C_SUCCESS,
        payload: {
          aumentoLineaTC
        }
      });
    } catch (error) {
      dispatch({ type: AUMENTO_LINEA_T_C_FAILURE });
      throw error;
    }
  };
}

export function adicionalTC(adicionalTCReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: ADICIONAL_T_C_REQUEST });

      const adicionalTC = await creditCardService.adicionalTC(adicionalTCReq);

      dispatch({
        type: ADICIONAL_T_C_SUCCESS,
        payload: {
          adicionalTC
        }
      });
    } catch (error) {
      dispatch({ type: ADICIONAL_T_C_FAILURE });
      throw error;
    }
  };
}
