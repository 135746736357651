import React, { useState } from 'react';

import GAException from 'src/components/GAException';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import SplashScreen from 'src/components/SplashScreen';
import functionalitiesService, { funcionalidades, msgOpcionNoDisponible } from 'src/services/functionalitiesService';
import accountService from 'src/services/accountService';
import transactionService, { toDefaultResponseError } from 'src/services/transactionService';
import { toUserLoginData } from 'src/services/authService';
import { useSelector } from 'react-redux';
import { registerLogin } from 'src/actions/accountActions';
import AccountService from '../../../services/accountService';
import getOS from 'src/hooks/getOS';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: '#fff',
    padding: '8px',
    paddingRight: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: '#fff',
    padding: '8px',
    paddingRight: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        //fontWeight: theme.typography.fontWeightMedium
        fontWeight: 600
      }
    }
  },
  icon: {
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.tertiary.main,
  },
  endIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.tertiary.main,
    strokeWidth: '4px',
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: '#fff',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.tertiary.main,
    }
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  endIcon: EndIcon,
  className,
  open: openProp,
  info: Info,
  onLogoutClick: onLogoutClick,
  onExternalLinkClick,
  onGenCodPromotorSucces,
  onMobileClose,
  ...rest
}) {

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const [userLogued, setUserLogued] = useState(JSON.parse(localStorage.getItem('userLogued')));
  const [legacyMode, setLegacyMode] = useState(getOS() === 'iPhone');

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.account);

  const opcionGenCodPromotor = '/app/promotorcliente';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };



  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const handleRedirectMasDimo = () => {
    setLoading(true);

    const param = {
      documento: userLogued.user.username,
      password: userLogued.user.passFidUser
    }

    const finishLoadingClose = () => {
      setLoading(false);
      onMobileClose();
    }

    AccountService.loginFidUser(param)
      .then((response) => {
        // console.log("Login con exito");

        //abrir url nueva dentro de la misma pestaña, usando window.location.href
        finishLoadingClose();
        
        const url = response.data;
        window.location.href = url;




        

      })
      .catch((error) => {
        console.log("Login fallido " + error)
        setLoading(false);
        onMobileClose();
      })
  }


  const style = { paddingLeft };

  const handleOnclick = () => {
    try {
      if(user.flagUserActivate === false || user.flagUsuarioValidadoATC === false){
        enqueueSnackbar("Para acceder a esta opción, debes completar tu registro.", {
          variant: 'info'
        });
        return;
      }
      let funcionalidadKey = "";

      if (href === "/app/cooperativas") {
        funcionalidadKey = funcionalidades.COOP.key;
      } else if (href === '/app/payment') {
        funcionalidadKey = funcionalidades.PAGOS.key;
      }
      else if (href === '/app/transferencias') {
        funcionalidadKey = funcionalidades.TRANSFER.key;
      }
      else if (href === '/app/account') {
        funcionalidadKey = funcionalidades.PERFIL.key;
      }
      else if (href === '/app/tarifas') {
        funcionalidadKey = funcionalidades.TARIF.key;
      }
      else if (href === opcionGenCodPromotor) {
        funcionalidadKey = funcionalidades.GEN_CODPROMOTOR.key;
      }
      else if (href === '/app/exchange') {
        funcionalidadKey = funcionalidades.MASDIMO.key;
      }

      if (funcionalidadKey !== "") {
        setLoading(true);
        functionalitiesService.funcionalidadHabilitada({ codigo: funcionalidadKey })
          .then((response) => {
            setLoading(false);
            if ((!(funcionalidadKey === funcionalidades.COOP.key) || user.flagUsuarioValidadoATC === true) && response === true) {
              if (href !== opcionGenCodPromotor) {
                // history.push(href); //comentado para que no redireccione a la pagina, sino que abra una nueva pestaña
                // console.log("href: " + href);
                // console.log( "legacyMode:===> " + legacyMode, getOS());


                if (getOS() === 'iPhone' || getOS() === 'Android') {
                  if (href === '/app/exchange') {
                    handleRedirectMasDimo();
                  } else {
                    history.push(href);
                  }
                } else if (legacyMode || getOS() === 'MacOS') {
                  if (href === '/app/exchange') {
                    handleRedirectMasDimo();
                  } else {
                    history.push(href);
                  }
                } else if (legacyMode || getOS() === 'Windows') {
                  history.push(href);
                } else {
                  history.push(href);
                }

              } else {
                //Generamos el CodPromotor del Cliente
                handleGenerarCodPromotor();
              }

            }
            else {
              enqueueSnackbar(user.flagUsuarioValidadoATC
                ? msgOpcionNoDisponible
                : 'La validación de su cuenta DIMO está en proceso, cuando culmine ya podrá vincular su CAJA DE AHORRO.', {
                variant: user.flagUsuarioValidadoATC ? 'info' : 'warning'
              });
            }
          })
          .catch(() => {
            setLoading(false);
            enqueueSnackbar(msgOpcionNoDisponible, {
              variant: 'info'
            });
          })
      }
      else {
        history.push(href);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(msgOpcionNoDisponible, {
        variant: 'info'
      });
    }
  }

  const handleGenerarCodPromotor = () => {
    try {
      setLoading(true);
      accountService.obtenerCodPromotorCliente()
        .then((codPromotor) => {
          setLoading(false);

          let userData = toUserLoginData(user);

          userData.codPromotor = codPromotor;

          registerLogin(userData);
          localStorage.setItem('userLogued', JSON.stringify({ authToken: localStorage.getItem('accessToken'), user: userData }));

          enqueueSnackbar("Su código de promotor fue generado exitosamente.", {
            variant: 'info'
          });

          onGenCodPromotorSucces(codPromotor);

        })
        .catch((error) => {

          let boolMsgError = false;
          const msgErrorDefault = 'No se pudo obtener el Codigo de Promotor, favor vuelta a intentarlo mas tarde.';
          const msgError = toDefaultResponseError(error, msgErrorDefault).error.message;

          if (msgError !== msgErrorDefault) {
            boolMsgError = true;
          }

          setLoading(false);

          enqueueSnackbar(
            msgError,
            {
              variant: boolMsgError ? 'info' : 'warning'
            }
          );

        })
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("No se pudo obtener el Codigo de Promotor, favor vuelta a intentarlo mas tarde.", {
        variant: 'info'
      });
    }
  }

  if (isLoading) {
    return <SplashScreen />
  }

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
          {EndIcon && (
            <EndIcon
              className={classes.endIcon}
              size="20"
            />
          )}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {getOS() === 'Android' ?
        <ListItem
          className={clsx(classes.itemLeaf, className)}
          disableGutters
          key={title}
          {...rest}
        >
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            style={style}
            //component={onLogoutClick ? Button : RouterLink}
            //onClick={onLogoutClick ? onLogoutClick : null}
            //exact
            //to={href}
            onClick={onLogoutClick ? onLogoutClick : (onExternalLinkClick ? () => { onExternalLinkClick(href) } : handleOnclick)}
          >
            {Icon && (
              <Icon
                className={classes.icon}
                size="20"
              />
            )}
            <span className={classes.title}>
              {title}
            </span>
            {Info && <Info className={classes.info} />}
            {EndIcon && (
              <EndIcon
                className={classes.endIcon}
                size="20"
              />
            )}
          </Button>
        </ListItem>
        :
        <>
          {title !== "Seguridad" &&
            <ListItem
              className={clsx(classes.itemLeaf, className)}
              disableGutters
              key={title}
              {...rest}
            >
              <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, `depth-${depth}`)}
                style={style}
                //component={onLogoutClick ? Button : RouterLink}
                //onClick={onLogoutClick ? onLogoutClick : null}
                //exact
                //to={href}
                onClick={onLogoutClick ? onLogoutClick : (onExternalLinkClick ? () => { onExternalLinkClick(href) } : handleOnclick)}
              >
                {Icon && (
                  <Icon
                    className={classes.icon}
                    size="20"
                  />
                )}
                <span className={classes.title}>
                  {title}
                </span>
                {Info && <Info className={classes.info} />}
                {EndIcon && (
                  <EndIcon
                    className={classes.endIcon}
                    size="20"
                  />
                )}
              </Button>
            </ListItem>
          }
        </>
      }
    </>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  endIcon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
