import React, { useEffect, useRef, useState } from 'react';

import GAException from 'src/components/GAException';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  Grid,
  Button,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'src/actions/accountActions';
import { getData as getDataActividad } from 'src/hooks/useRegistrarActividadDimo';

import {
  ChevronRight,
  Gift,
  LogOut,
  Shield,
} from 'react-feather';

import {
  GroupAdd as GroupAddIcon
} from '@material-ui/icons'

import Logo, { LogoResize } from 'src/components/Logo';
import NavItem from './NavItem';

import { Home, Dimo, Transfer, User, Cooperative, Card, QR, Tarifario, Ayuda, MiQR } from './icons';
import accountService from 'src/services/accountService';
import { toUserLoginData } from 'src/services/authService';
import QRCode from 'qrcode.react';
import qrService from '../../../services/qrService';
import { useSnackbar } from 'notistack';
import { VERSION_CODE } from 'src/constants';
import Lock from '@material-ui/icons/Lock';

const navConfig = [
  {
    //subheader: 'Menu DIMO',
    items: [
      {
        title: 'Inicio',
        icon: Home,
        endIcon: ChevronRight,
        href: '/app',
        visible: true,
      },
      {
        title: 'Cuentas Cooperativas',
        icon: Cooperative,
        endIcon: ChevronRight,
        href: '/app/cooperativas',
        visible: true,
      },
      {
        title: 'Pagos',
        icon: Card,
        endIcon: ChevronRight,
        href: '/app/payment',
        visible: true,
      },
      {
        title: 'Transferencias y Giros',
        icon: Transfer,
        endIcon: ChevronRight,
        href: '/app/transferencias',
        visible: true,
      },
      {
        title: 'Perfil de Usuario',
        icon: User,
        endIcon: ChevronRight,
        href: '/app/account',
        visible: true,
      },
      {
        title: 'Tarifario',
        icon: Tarifario,
        endIcon: ChevronRight,
        href: '/app/tarifas',
        visible: true,
      },
      {
        title: 'Mi QR',
        icon: MiQR,
        endIcon: ChevronRight,
        href: '/app/qr/mi-qr',
        visible: true,
      },
      {
        title: '+dimo',
        icon: Gift,
        endIcon: ChevronRight,
        href: '/app/exchange',
        visible: true,
      },
      {
        title: 'Ayuda',
        icon: Ayuda,
        endIcon: ChevronRight,
        href: 'https://wa.me/595217297777',
        externalLink: true,
        visible: true,
      },
      {
        title: 'Seguridad',
        icon: Lock,
        endIcon: ChevronRight,
        href: '/app/seguridad',
        visible: true,
      },
      {
        title: 'Generá tú código Promotor',
        icon: GroupAddIcon,
        endIcon: ChevronRight,
        href: '/app/promotorcliente',
        visible: true,
      },
      /*{
        title: 'Generar QR (Demo)',
        icon: QR,
        endIcon: ChevronRight,
        href: '/app/demo/qr/generacion'
      },*/
      {
        title: 'Política de privacidad',
        icon: Shield,
        endIcon: ChevronRight,
        href: '/app/privacidad',
        visible: true,
      },
      {
        title: 'Cerrar Sesión',
        icon: LogOut,
        endIcon: ChevronRight,
        href: '/login',
        logout: true,
        visible: true,
      },
    ]
  },
];

const opcionesContacto = [
  {
    alt: 'Whatsapp',
    iconoSrc: "/static/images/dimo/icon_card_green.png",
    href: ''
  },
  {
    alt: 'Facebook',
    iconoSrc: "/static/images/dimo/icon_card_green.png",
    href: ''
  }
]

function renderNavItems({ items, account, codPromotor, onMobileClose, ...rest }) {

  let itemFiltered = [];

  // Filtramos solo los visibles...
  const _items = items.filter(x => x.visible);

  const { user } = account;

  for (let i = 0; i < _items.length; i++) {
    const item = _items[i];

    if (user.flagUserActivate) {

      if (item.href === '/app/promotorcliente') {
        //if (toUserLoginData(user).codPromotor === "" || toUserLoginData(user).codPromotor === "null") {
        if (codPromotor === "" || codPromotor === "null") {
          itemFiltered.push(item);
        }
      } else {
        itemFiltered.push(item);
      }


    }
    else {
      if (
        !(item.href === "/app/cooperativas" ||
          item.href === "/app/payment" ||
          item.href === "/app/transferencias")
      ) {
        itemFiltered.push(item);
      }
    }
  }


  return (
    <List disablePadding>
      {itemFiltered.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, onMobileClose, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  logout,
  externalLink,
  onGenCodPromotorSucces,
  onMobileClose
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        endIcon={item.endIcon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        endIcon={item.endIcon}
        key={key}
        info={item.info}
        title={item.title}
        onLogoutClick={item.logout ? logout : null}
        onExternalLinkClick={item.externalLink ? externalLink : null}
        onGenCodPromotorSucces={onGenCodPromotorSucces}
        onMobileClose={onMobileClose}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  root: theme.menuView.root,
  mobileDrawer: theme.menuView.mobileDrawer,
  desktopDrawer: theme.menuView.desktopDrawer,
  avatar: theme.menuView.avatar,
  listSubheader: {
    color: '#FFF'
  },
  link: {
    color: '#FFF'
  },
  logo: {
    width: 153,
    paddingTop: 30,
    '& img': {
      width: '100%!important',
    }
  },
  codPromotorTitle: {
    //color: '#FFF',
    color: theme.palette.tertiary.main,
  },
  codPromotorValue: {
    color: '#FFF',
    marginBottom: '100px',
  },
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { user } = account;
  const [codPromotor, setCodPromotor] = useState(toUserLoginData(user).codPromotor);
  const { innerWidth: width, innerHeight: height } = window;


  const handleLogout = async () => {
    try {
      accountService.registrarActividadFrontend("Logout", "/logout", getDataActividad());
      await dispatch(logout());
      history.push('/login');
    } catch (error) {
      GAException(error);
    }
  };

  const handleClickExternal = (url) => {

    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    //5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      className={classes.root}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            // p={2}
            // display="flex"
            // justifyContent="center"
            style={{ marginTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          >
            <RouterLink to="/">
              <LogoResize
                width='140px'
              />
            </RouterLink>

            {height <= 667 && (
              <Box
                // textAlign="center"
                // mt={2}
                mb={2}
              // style={{ position: 'absolute', bottom: '10px', width: '100%', height: '50px' }}
              >
                <Typography
                  variant="body2"
                  style={{ color: '#99E5FF', fontSize: '12px' }}
                >
                  Versión {VERSION_CODE}
                </Typography>
              </Box>
            )}
          </Box>
        </Hidden>
        <Hidden mdDown>
          {/* <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <Box
              className={classes.logo}
            >
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </Box> */}
          <Box
            // p={2}
            // display="flex"
            // justifyContent="center"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              className={classes.logo}
            >
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>

            {height <= 667 && (
              <Box
                // textAlign="center"
                // mt={2}
                mb={2}
              // style={{ position: 'absolute', bottom: '10px', width: '100%', height: '50px' }}
              >
                <Typography
                  variant="body2"
                  style={{ color: '#99E5FF', fontSize: '12px' }}
                >
                  Versión {VERSION_CODE}
                </Typography>
              </Box>
            )}
          </Box>
        </Hidden>
        <Box
          p={2}
          pr={0}
        >
          {navConfig.map((config, i) => (
            <List
              key={i + '-NavBar-List'}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  className={classes.listSubheader}
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
                account: account,
                codPromotor: codPromotor,
                logout: () => handleLogout(),
                externalLink: (url) => handleClickExternal(url),
                onGenCodPromotorSucces: (codPromotor) => { setCodPromotor(codPromotor) },
                onMobileClose: onMobileClose,
              })}
            </List>
          ))}

          {codPromotor !== "" && codPromotor !== "null" && (
            <Box
            // mr={4}
            // ml={4}
            // mt={2}
            // mb={3}
            //style={{border:"1px solid #A1F750"}}
            >
              <Typography
                //variant='h6'
                variant='body2'
                align='center'
                className={classes.codPromotorTitle}
              >
                {'Código Promotor'}
              </Typography>

              <Typography
                variant='h1'
                align='center'
                className={classes.codPromotorValue}
              >
                {codPromotor}
              </Typography>
            </Box>
          )}


        </Box>
        {/*
        <Box mt={2} mb={10}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            //justify="space-between"
            //style={{ justifyContent: 'space-between'}}
          >
            {opcionesContacto.map(opcion =>
            <Grid
              item
              //xs={2}
            >
              <Button onClick={() => {handleClickContactUs(opcion.href)}}>
                <img
                  src={opcion.iconoSrc}
                  alt={opcion.alt}
                />
              </Button>
            </Grid>
            )}
          </Grid>
        </Box>
        */}
        {/**
         *
         * <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Necesitas Ayuda?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Ingresa Aquí
            </Link>
          </Box>
        </Box> */ }



      </PerfectScrollbar>

      {/*si height es menor de 667px (iphone 8) se muestra el footer*/}
      {height > 667 && (
        <Box
          textAlign="center"
          // mt={2}
          mb={2}
          style={{ position: 'absolute', bottom: '10px', width: '100%', height: '50px' }}
        >
          <Typography
            variant="body2"
            style={{ color: '#99E5FF', fontSize: '12px' }}
          >
            Versión {VERSION_CODE}
          </Typography>
        </Box>
      )}

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
