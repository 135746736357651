/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CIUDADES_REQUEST,
  CIUDADES_SUCCESS,
  CIUDADES_FAILURE,
  PAISES_REQUEST,
  PAISES_SUCCESS,
  PAISES_FAILURE
} from 'src/actions/locationActions';

const initialState = {
  user: null
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CIUDADES_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CIUDADES_SUCCESS: {
      const { ciudades } = action.payload;

      return produce(state, (draft) => {
        draft.ciudades = ciudades;
      });
    }

    case CIUDADES_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case PAISES_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case PAISES_SUCCESS: {
      const { paises } = action.payload;

      return produce(state, (draft) => {
        draft.paises = paises;
      });
    }

    case PAISES_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    default: {
      return state;
    }
  }
};

export default locationReducer;
