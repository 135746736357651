class TokenService {

  getLocalAccessToken() {
    return localStorage.getItem('accessToken');
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('userLogued'));
    user.accessToken = token;
    localStorage.setItem('userLogued', JSON.stringify(user));
    localStorage.setItem('accessToken', token);
  }

  getuserLogued() {
    return JSON.parse(localStorage.getItem("userLogued"));
  }

  setuserLogued(userLogued) {
    // console.log(JSON.stringify(userLogued));
    localStorage.setItem("userLogued", JSON.stringify(userLogued));
  }

  removeuserLogued() {
    localStorage.removeItem("userLogued");
  }
}

export default new TokenService();
