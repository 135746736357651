export default {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Barlow',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px',
    fontFamily: 'Barlow',
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px',
    fontFamily: 'Barlow',
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
    fontFamily: 'Barlow',
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
    fontFamily: 'Barlow',
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
    fontFamily: 'Barlow',
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
    fontFamily: 'Barlow',
  },
  body1: {
    fontFamily: 'Barlow',
  },
  body2: {
    fontFamily: 'Barlow',
  },
  overline: {
    fontWeight: 500
  }
};
