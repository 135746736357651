import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import useRegistrarActividadDimo from 'src/hooks/useRegistrarActividadDimo';

function GuestRoute({ component: Component, render, ...rest }) {
  const account = useSelector((state) => state.account);
  const location = useLocation();

  useRegistrarActividadDimo(location.pathname, location.state);

  if (account.user) {
    return <Redirect to="/app" />;
  }

  return render ? render({ ...rest }) : <Component {...rest} />;
}

GuestRoute.propTypes = {
  component: PropTypes.any,
  render: PropTypes.func
};

export default GuestRoute;
