import axios from 'axios';

import {
  dimo_service_api_url as API_URL_DIMO
} from 'src/services/backendApiService';
import authService from './authService';
import TokenService from './TokenService';

const HEADERS = () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + TokenService.getLocalAccessToken()
  }
  return headers;
}

const getUser = () => {
  return authService.getUserData();
}

export const funcionalidades = {
  TRANSFER: {
    key: "TRANSFER",
    desc: "Tranferencias",
  },
  TRANSFERCOOP: {
    key: "TRANSFERCOOP",
    desc: "Tranferencias a Cooperativas",
  },
  TRANSFERBILLE: {
    key: "TRANSFERBILLE",
    desc: "Tranferencias Otras Billeteras",
  },
  TRANSFERBANCOS: {
    key: "TRANSFERBANCOS",
    desc: "Tranferencias con Bancos",
  },
  TRANSFERDIMO: {
    key: "TRANSFERDIMO",
    desc: "Tranferencia con DIMO",
  },
  PAGOS: {
    key: "PAGOS",
    desc: "Acceso a la opcion de Pagos",
  },
  PAGOSSERVICIOS: {
    key: "PAGOSSERVICIOS",
    desc: "Pago de Servicios PRONET NETEL",
    detalle: "",
  },
  PAGOSCABALTC: {
    key: "PAGOSCABALTC",
    desc: "Pago de Extractro de TC Cabal",
  },
  PAGOSCOOPCUENTAS: {
    key: "PAGOSCOOPCUENTAS",
    desc: "Pago de Cuentas Cooperativas",
  },
  COOP: {
    key: "COOP",
    desc: "Acceso a las opciones de las Cuentas Cooperativas",
    detalle: "",
  },
  COOP_AGREGAR: {
    key: "COOP_AGREGAR",
    desc: "Permiso para acceder a la funcionalidad de Agregar Cooperativas",
  },
  QR: {
    key: "QR",
    desc: "Operaciones con QR",
    detalle: "",
  },
  SICOOP_EFECTIVO: {
    key: "SICOOP_EFECTIVO",
    desc: "Operaciones con efectivo",
    detalle: ""
  },
  CAJA_COOPERATIVA: {
    key: "CAJA_COOPERATIVA",
    desc: "Operaciones de caja cooperativas",
    detalle: ""
  },
  QRCOMPRAS: {
    key: "QRCOMPRAS",
    desc: "Operaciones con QR",
    detalle: "Compras",
  },
  QREXTRACCION: {
    key: "QREXTRACCION",
    desc: "Operaciones con QR",
    detalle: "Extraccion de dinero",
  },
  MASDIMO: {
    key: "MAS_DIMO",
    desc: "Operaciones con +Dimo",
    detalle: "Operaciones de +Dimo",
  },
  QRTRANSFERENCIAS: {
    key: "QRTRANSFERENCIAS",
    desc: "Operaciones con QR",
    detalle: "Transferencias",
  },
  QRTRANSFERENCIASREQ: {
    key: "QRTRANSFERENCIASREQ",
    desc: "Operaciones con QR",
    detalle: "Pedir Transferencias",
  },
  QRTRANSFERENCIASSEND: {
    key: "QRTRANSFERENCIASSEND",
    desc: "Operaciones con QR",
    detalle: "Hacer Transferencias",
  },
  RECARGASALDO: {
    key: "RECARGASALDO",
    desc: "Recarga de Saldo",
  },
  TARIF: {
    key: "TARIF",
    desc: "Acceso a la opcion del Tarifario",
    detalle: "",
  },
  PERFIL: {
    key: "PERFIL",
    desc: "Acceder a la opcion del Perfil de Usuario",
    detalle: "",
    subItems: {
      PERFILMOD_DATA: {
        key: "PERFILMOD_DATA",
        desc: "Modificar los datos del Perfil del usuario",
      },
      PERFILMOD_PASS: {
        key: "PERFILMOD_PASS",
        desc: "Modificar la contraseña del usuario",
      },
      PERFILMOD_EMAIL: {
        key: "PERFILMOD_EMAIL",
        desc: "Modificar el email del usuario",
      },
      PERFILMOD_CELLPHONE: {
        key: "PERFILMOD_CELLPHONE",
        desc: "Modificar el numero celular del usuario",
      },
    }
  },
  TPDIMO: {
    key: "TPDIMO",
    desc: "Acceder al Detalle de las Tarjeta DIMO",
    detalle: "",
  },
  TPDIMOFACTURAS: {
    key: "TPDIMOFACTURAS",
    desc: "Acceder a la funcionalidad de Ver Facturas de DIMO",
    detalle: "",
  },
  WHITELIST: {
    key: "WHITELIST",
    desc: "Habilitar White Lista para registro de Usuarios",
    detalle: "",
  },
  LANDPAGE_MSGMODAL: {
    key: "LANDPAGE_MSGMODAL",
    desc: "Habilitar o no el despliegue del Mensaje Dialog en el Landing Page de Dimo",
    detalle: "",
  },
  PASSRECOVER: {
    key: "PASSRECOVER",
    desc: "Verificar si un usario tiene permisos para recuperar su contraseña",
    detalle: "",
  },
  GEN_CODPROMOTOR: {
    key: "GEN_CODPROMOTOR",
    desc: "Acceso a la opción de generacion del código de promotor del Cliente",
    detalle: "",
  }
}

export const msgOpcionNoDisponible = 'Esta opción estará disponible muy pronto.';
export const msgOpcionNoDisponiblePassRecovery = 'Para recuperar su contraseña, se estarán comunicando con usted el call center DIMO o en todo caso comuníquese al 021 729 7777.';
export const msgOpcionNoDisponibleChangePass = 'Para el cambio de contraseña comuníquese al 021 729 7777.';
export const msgOpcionNoDisponibleChangeEmail = 'Para el cambio de correo electronico comuníquese al 021 729 7777.';
export const msgOpcionNoDisponibleChangePhoneNumber = 'Para el cambio de numero de telefono comuníquese al 021 729 7777.';
export const msgOpcionNoDisponibleChangeNombreApellido = 'Para el cambio de nombre o apellido comuníquese al 021 729 7777.';

class FunctionalitiesService {
  funcionalidadHabilitada = (dataPrm) => new Promise((resolve, reject) => {
    let dataPost = {
      codigo: dataPrm.hasOwnProperty("codigo") ? dataPrm.codigo : "",
      documento: dataPrm.hasOwnProperty("documento") ? dataPrm.documento : "",
    }

    if (dataPost.documento === "") {
      try { dataPost.documento = getUser() ? getUser().document ? getUser().document : "" : "" ; } catch(e){console.log("funcionalidad-sin-doc-ex")}
    }


    axios.post(API_URL_DIMO + '/ws_funcionalidad_dimo', dataPost,)
      .then((response) => {
        if (response.data.hasOwnProperty('codRespuesta') && response.data.hasOwnProperty('estado')) {
          if (response.data.codRespuesta === '0') {
            resolve(response.data.estado === "1" ? true : false)
          }
          else {
            reject({ error: { message: response.data.txtRespuesta } });
          }
        }
        else {
          // Response Incorrecto
          //console.log("response incorrecto");
          reject({ error: {} });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  funcionalidadTrx = (dataPrm, codParticipante, tipoMov) => new Promise((resolve, reject) => {

    let sicoopParticipants = JSON.parse(localStorage.getItem("sicoopParticipants"));

    //console.log('codParticipante', codParticipante);
    //console.log('sicoopParticipants', sicoopParticipants);

    let result = false;

    sicoopParticipants.forEach(element => {

      //console.log('element', element.codParticipante);
      //console.log('datosTrx', element.datosTrx);

      if (element.codParticipante === codParticipante) {
        let datosTrx = element.datosTrx;

        // console.log('datosTrx', element.datosTrx);

        if (datosTrx) {
          datosTrx.funcionalidad.forEach(item => {

            if (item.tipoTrx === dataPrm.codigo) {

              // console.log('item.tipoTrx', item.tipoTrx);
              // console.log('dataPrm.codigo', dataPrm.codigo);
              // console.log('tipoMov', tipoMov);
              // console.log('item.origen', item.origen);
              // console.log('item.destino', item.destino);
              // console.log('item.destino', item.destino === '0' ? true : false);

              if (tipoMov === 'ORIGEN') {
                result = item.origen === '0' ? true : false
              } else if (tipoMov === 'DESTINO') {
                result = item.destino === '0' ? true : false
              }
            }
          });
        }
      }
    });

    resolve(result)

  });
}

const functionalitiesService = new FunctionalitiesService();

export default functionalitiesService;
