import React from 'react';

export function Home({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_home_green.png"}
    />
  )
}

export function Card({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_card_green.png"}
    />
  )
}

export function Cooperative({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_cooperative_green.png"}
    />
  )
}

export function Dimo({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_dimo_green.png"}
    />
  )
}

export function Transfer({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_transfer_green.png"}
    />
  )
}

export function User({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_user_green.png"}
    />
  )
}

export function QR({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      alt="float_button"
      src={"/static/images/dimo/icon_qr_green.png"}
    />
  )
}

export function RefillMinute({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 0 }}
      src={"/static/images/dimo/icon_minute_refill_green.png"}
      alt="icon_minute_refill_green.png"
    />
  )
}
