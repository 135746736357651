/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  OBTENER_CIERRE_EMISOR_REQUEST,
  OBTENER_CIERRE_EMISOR_SUCCESS,
  OBTENER_CIERRE_EMISOR_FAILURE,
  CONSULTA_EMISOR_TARJETA_REQUEST,
  CONSULTA_EMISOR_TARJETA_SUCCESS,
  CONSULTA_EMISOR_TARJETA_FAILURE
} from 'src/actions/entityActions';

const initialState = {
  user: null
};

const entityReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSULTA_EMISOR_TARJETA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTA_EMISOR_TARJETA_SUCCESS: {
      const { consultaEmisorTarjeta } = action.payload;

      return produce(state, (draft) => {
        draft.consultaEmisorTarjeta = consultaEmisorTarjeta;
      });
    }

    case CONSULTA_EMISOR_TARJETA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case OBTENER_CIERRE_EMISOR_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case OBTENER_CIERRE_EMISOR_SUCCESS: {
      const { obtenerCierreEmisor } = action.payload;

      return produce(state, (draft) => {
        draft.obtenerCierreEmisor = obtenerCierreEmisor;
      });
    }

    case OBTENER_CIERRE_EMISOR_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    default: {
      return state;
    }
  }
};

export default entityReducer;
