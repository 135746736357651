import React from 'react';

import GAException from 'src/components/GAException';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthRouteRegisterOrFromMiCabal from './AuthRouteRegisterOrFromMiCabal';
import { toCreditCardData } from 'src/services/creditCardService';

function AuthRouteTCRegisterDIMO({ children }) {
  const account = useSelector((state) => state.account);
  const history = useHistory();

  const propsState = getPropsState(children);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (!account.user.flagUserActivate && !account.user.flagUsuarioMiCabal) {
    return AuthRouteRegisterOrFromMiCabal({children: children});
  }

  if (!propsState || !propsState.creditCard) {
    return <Redirect to="/app" />;
  }

  const creditCardData = toCreditCardData(propsState.creditCard);

  // Ult. validacion de que si existan una tarjeta cargada
  if (!(creditCardData.NUMERO_TARJETA.length > 1)) {
    return <Redirect to="/app" />;
  }
  else {
    // Validamos si la tarjeta en cuestion esta vinculada a DIMO
    if (creditCardData.VINCULADA_DIMO !== 'S') {
      history.replace({ pathname: "/app" })
      history.push({
        pathname: "/app/creditcard/vincularDimoTC",
        state: {
          creditCard: creditCardData,
        }
      });
    }
  }

  // Si llegamos hasta aqui, continuamos normalmente...
  return children;
}


function getPropsState(children) {
  try {
    return children.props.children.props.location.state;
  }
  catch (e) {
    return null;
  }
}


AuthRouteTCRegisterDIMO.propTypes = {
  children: PropTypes.any
};

export default AuthRouteTCRegisterDIMO;
