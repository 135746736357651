import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import chatReducer from './chatReducer';
import mailReducer from './mailReducer';
import kanbanReducer from './kanbanReducer';
import creditCardReducer from './creditCardReducer';
import prepaidCardReducer from './prepaidCardReducer';
import locationReducer from './locationReducer';
import entityReducer from './entityReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  mail: mailReducer,
  kanban: kanbanReducer,
  creditCard: creditCardReducer,
  prepaidCard: prepaidCardReducer,
  location: locationReducer,
  entity: entityReducer,
  form: formReducer
});

export default rootReducer;
