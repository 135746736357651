import ReactGA from 'react-ga';

export default function GAException(error) {

  let errorValue = '';

  if (error.message === null) {
    errorValue = error;
  } else if (error.message === undefined) {
    errorValue = JSON.stringify(error);
  } else {
    errorValue = error.message + ' - ' + error.toString();
  }

  ReactGA.exception({
    page: window.location.pathname,
    description: 'Error - ' + errorValue
  });

}
