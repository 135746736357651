import { colors } from '@material-ui/core';

export const backgroundColor = '#EEEEEE';
export const primary = '#0061d8';
export const secondary = '#1C73FE';
export const tertiary = '#A1F750';
export const textPrimary = '#152C3B';
export const textSecondary = '#707070';
export const textTertiary = '#727C8E';

