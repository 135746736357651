import React from 'react';

import GAException from 'src/components/GAException';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { LogoBlue } from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  },
  logo: {
    width: 200,
    maxWidth: '100%'
  }
}));

function SplashScreen({splashScreenMessage, ...rest}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        //mb={6}
      >
        <LogoBlue className={classes.logo} />
      </Box>
      { splashScreenMessage !== null && (
      <Box mt={4}>
        <Typography
          variant="h3"
          color="secondary"
        >
          {splashScreenMessage}
        </Typography>
      </Box>
      )}
      <Box mt={6}>
      <CircularProgress />
      </Box>
    </div>
  );
}

SplashScreen.propTypes = {
  splashScreenMessage: PropTypes.string,
}

SplashScreen.defaultProps = {
  splashScreenMessage: null,
};

export default SplashScreen;
