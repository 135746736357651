import React from 'react'
/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const VERSION_CODE = '2.17.1.16';
export const VERSION_APP = {
  appVersion: '0.0.1',
}

export const VERSION_CACHE = {
  appVersion: '0.0.1',
}

export const HEADER = {
  idSesion: "102",
  idRequest: "123456",
  codigoEF: "csoto",
  canal: "102",
  idClienteEF: "prueba",
  ciCliente: "csoto"
}

export const PREPAID_CARD_API_URL = 'http://localhost:7095/api/ceibo/tarjetaPrepaga/v1';
export const IMG_URL = 'https://estatico.cabal.coop.py/public/micabal/images-front/entities/';
export const IMG_DIMO_URL = 'https://estatico.cabal.coop.py/public/micabal/images-front/dimo/';
export const IMG_CARD_URL_GRAL = 'https://estatico.cabal.coop.py/public/micabal/images-front/entities/00.png';

export const TERMS_AND_CONDITIONS = () => {
  return (<>
  <h5 style={{color:"#1C73FE", textAlign:"center"}}>CONTRATO DE PRODUCTOS Y SERVICIOS DIMO</h5>
  <br/>
  <p style={{fontSize: "12px"}}>
    <b>COOPERATIVA BINACIONAL DE SERVICIOS CABAL LIMITADA (CABAL) y el USUARIO</b>, cuyo nombre, denominación o razón social y
    demás datos de identificación y contacto, así como el lugar y fecha del presente, se consignan en los campos correspondientes,
    celebran el presente contrato de prestación de servicios, que se regirá por los siguientes artículos:

    <br/><br/>

    <h4 style={{color:"#1C73FE"}}>A) DISPOSICIONES GENERALES</h4>
    <b>Artículo 1º.</b> El presente contrato establece las condiciones de prestación de los productos y servicios DIMO por parte de CABAL para el usuario. <br/>
    <b>Artículo 2º.</b> Para una correcta interpretación, se adoptan las siguientes definiciones:

    <div style={{paddingLeft:"20px"}}>

      <b>1. SICOOP:</b> Sistema de Interconexión Cooperativo.
      <br/>
      <b>2. CABAL: </b>Cooperativa Binacional de Servicios Cabal Paraguay Limitada, entidad titular del sistema SICOOP, que coordina y centraliza su administración en la República del Paraguay, y brinda los servicios necesarios para su correcto funcionamiento.
      <br/>
      <b>3. USUARIO: </b>Persona física, socio o cliente de CABAL y/o de las entidades SICOOP, que: <br/>
      <div style={{paddingLeft:"20px"}}>
        (a) suscribe el presente contrato con CABAL para acceder a los servicios DIMO y operar con la tarjeta prepaga DIMO, la app DIMO y la web DIMO en las modalidades que estas le permitan; y,
        <br/>
        (b) autoriza expresamente a CABAL a solicitar, obtener, administrar y entregar por medios electrónicos u otros la información personal y de los productos y servicios con los que opera el usuario en las entidades SICOOP.
      </div>
      <b>4. ENTIDADES SICOOP: </b>Entidades vinculadas al SICOOP administrado por CABAL. CABAL publicará mensualmente, en su sitio web oficial, el listado actualizado de las entidades SICOOP.
      <br/>
      <b>5. PRODUCTOS Y SERVICIOS DIMO: </b>App DIMO, web DIMO, tarjeta prepaga DIMO, incluidas las operaciones que estos productos habilitan, y los demás productos y servicios que defina CABAL.
      <br/>
      <b>6. APP DIMO: </b>Aplicación para teléfonos y otros dispositivos móviles desarrollada o disponibilizada por CABAL y destinada al usuario a fin de que el mismo pueda realizar sus operaciones con los productos y servicios DIMO a los que tuviere acceso.
      <br/>
      <b>7. WEB DIMO: </b>Página o plataforma web desarrollada o disponibilizada por CABAL y destinada al usuario a fin de que el mismo pueda realizar operaciones con los productos y servicios DIMO a los que tuviere acceso.
      <br/>
      <b>8. TARJETA PREPAGA DIMO: </b>Tarjeta digital y/o tarjeta física con banda magnética, chip, o la tecnología que defina CABAL para la operación de aquellos usuarios que hayan suscripto con CABAL el presente contrato.
      <br/>
      <b>9. TRANSFERENCIA: </b>Transferencia electrónica de dinero realizada u ordenada por el usuario desde una cuenta, tarjeta prepaga o producto similar de su titularidad a otra cuenta, tarjeta prepaga o producto similar de su titularidad, o de un tercero que sea titular de una cuenta existente en una entidad SICOOP o vinculada al SICOOP, a criterio de CABAL.
      <br/>
      <b>10. GIRO: </b>Remisión electrónica de dinero a un tercero que no es usuario, la cual puede ser efectivizada a través de los canales habilitados y comunicados por CABAL para el efecto.
      <br/>
      <b>11. REDES DE COBRANZAS: </b>Empresas especializadas en cobros de facturas o similar con las que CABAL establezca relación contractual para que los usuarios realicen las operaciones.
      <br/>
      <b>12. CORRESPONSALES: </b>Puntos de atención habilitados por CABAL, pudiendo ser estos propios o de terceros para brindar los servicios relacionados al presente contrato.
      <br/>
      <b>13. CONVERSIÓN o CASH IN: </b>Operación a través de la cual se acredita un saldo electrónico en una tarjeta prepaga DIMO por un importe igual al que se recibe en efectivo, deducidas las comisiones que correspondan, almacenándose el valor monetario en la tarjeta.
      <br/>
      <b>14. RECONVERSIÓN o CASH OUT: </b>Operación inversa a la anterior a través de la cual se transforma un saldo electrónico de una tarjeta prepaga DIMO a dinero en efectivo, pudiendo deducirse comisiones dependiendo del origen del dinero electrónico y del servicio utilizado por el usuario.
      <br/>
      <b>15.  ACREDITACIÓN: </b>Recepción de fondos en una tarjeta prepaga DIMO por medios distintos a los de una conversión.
      <br/>
      <b>16. SOLICITUD DE USUARIO: </b>Mensaje de datos, solicitud u opción que el usuario transmite, realiza o acepta digitalmente a través de la app SICOOP y/o la web SICOOP, para acceder a módulos, productos y servicios DIMO.
      <br/>
    </div>
    <b>Artículo 3º. </b> El usuario que suscriba el presente contrato de prestación de servicios con CABAL tendrá acceso a la APP y a la WEB DIMO disponibilizada por CABAL en las tiendas de aplicaciones de Android, IOS o donde Cabal disponibilice la misma, en base a los términos y condiciones fijados en el presente, sus anexos y los que CABAL defina y comunique en el futuro.
    <br/>
    Adicionalmente, el usuario que sea socio o cliente de entidades SICOOP podrá operar con estas entidades a través de los productos y servicios disponibilizados en la APP y en la WEB DIMO. A tal efecto, el usuario brinda todas las autorizaciones pertinentes, incluidas las asociadas a datos e informaciones personales y patrimoniales que sean necesarias en la operativa de los productos y servicios, y exime de todas las responsabilidades emergentes, no imputables a CABAL.
    <br/>
    <b>Artículo 4º. </b>La app DIMO y la web DIMO contendrán los campos necesarios para que el usuario pueda solicitar electrónicamente la tarjeta prepaga DIMO y los demás productos y servicios incluidos en el presente contrato y las modificaciones decididas por CABAL.
    <br/>
    <br/>
    <h4 style={{color:"#1C73FE"}}>B) PRODUCTOS Y SERVICIOS</h4>
    <b>B1) APP DIMO y WEB DIMO:</b>

    <b>Artículo 5º. </b>La app DIMO y la web DIMO para los teléfonos inteligentes, dispositivos móviles y computadoras serán desarrolladas, mantenidas y gestionadas por CABAL. Ambas serán disponibilizadas en las tiendas digitales de los fabricantes definidos por CABAL.
    <br/>
    <b>Artículo 6º. </b> La app DIMO y la web DIMO permiten al usuario operar directamente sobre los diversos servicios habilitados por Cabal en dichos canales, entre los que se citan:
    <br/>
    <div style={{paddingLeft:"20px"}}>
      <b>6.1. Módulo de Consultas de Cuentas SICOOP: </b>permite al usuario DIMO a través de su APP y WEB consultar el Estado de Cuenta del Usuario con cualquiera de las Entidades participantes del SICOOP en cualquiera de los servicios con los cuales opera el usuario en las mismas.
      <br/>
      <b>6.2. Módulo de Transferencias: </b>permite al usuario DIMO a través de su APP y WEB  recibir y enviar transferencias electrónicas de dinero desde su aplicación pudiendo ser el origen o destino de las mismas cuentas de su titularidad en cualquiera de las Entidades SICOOP, cuentas de terceros usuarios de DIMO, o el destino que el usuario defina a través de cualquiera de los canales habilitados por Cabal para el efecto.
      <br/>
      <b>6.3. Módulo de Pagos de Servicios: </b>permite al usuario DIMO a través de su APP y WEB realizar pagos de Obligaciones con las Entidades SICOOP o de servicios públicos y privados habilitados por Cabal para el efecto.
      <br/>
      <b>6.4. Tarjeta Prepaga DIMO: </b>al momento de bajar la aplicación y suscribir el presente contrato de servicios el usuario DIMO acepta y accede inmediatamente a una Tarjeta Prepaga DIMO.
      <br/>
      La tarjeta Prepaga DIMO podrá ser solicitada en formato físico por el usuario a través de la APP o la WEB DIMO.
    </div>

    <br/>
    La descripción básica de servicios descripta anteriormente no es limitativa por lo que Cabal podrá agregar otras funcionalidades y/o servicios al usuario DIMO.
    <br/>
    La exposición de la información relacionada al usuario en cualquiera de las ENTIDADADES SICOOP dentro de la APP y WEB DIMO es responsabilidad exclusiva de la ENTIDAD. CABAL no asume responsabilidad alguna por los datos e informaciones proveídas por las entidades SICOOP.
    <br/><br/>
    <b>B2) TARJETA PREPAGA DIMO:</b>
    <br/>
    <b>Artículo 7º. </b>CABAL definirá las condiciones operativas del funcionamiento de la Tarjeta Prepaga DIMO como así también los distintos canales sobre los cuales ella podrá operar y las condiciones de operación de la misma.
    <br/>
    <b>Artículo 8º. </b>Solo CABAL podrá imprimir y grabar las tarjetas prepagas a solicitud del usuario. Las tarjetas estarán en condiciones de ser utilizadas en los comercios adheridos a la Red CABAL y en los demás comercios, canales y operaciones que defina CABAL.
    <br/>
    <b>Artículo 9º. </b>Desde el momento de bajar la APP DIMO y aceptar las condiciones del presente contrato el usuario acepta la emisión de una tarjeta PREPAGA DIMO en formato digital.
      <br/>
      El límite de disponibilidad de fondos en la tarjeta será el importe acreditado por el usuario titular de la misma. Los saldos disponibles no devengarán intereses y podrán ser verificados en los estados de cuentas disponibles en la app DIMO y la web DIMO.
      <br/>
      Las tarjetas serán recargables y las recargas de saldo podrán hacerse en los puntos y corresponsales habilitados y en las formas que determine CABAL.
      <br/>
    <b>Artículo 10º. </b>Una vez recibida la tarjeta prepaga física o digital, el usuario dispondrá de un plazo de (…) días para retractarse y dejar sin efecto el presente contrato; si así no lo hiciere, se considerarán aceptados los términos y condiciones.
      <br/>
      La utilización de la tarjeta prepaga antes de fenecido dicho plazo, se tomará como prueba fehaciente de la aceptación de estos términos.
      <br/>
    <b>Artículo 11º. </b>La tarjeta prepaga es propiedad de CABAL. El usuario es tenedor de la misma y se hace responsable de su guarda y correcta utilización. El usuario tiene única y exclusivamente el derecho a usarla en la forma prevista en este contrato.
    <br/>
    <b>Artículo 12º. </b>El plazo de validez de la tarjeta prepaga será́ hasta la fecha de vencimiento que constará en la misma; no obstante, la misma perderá́ validez en forma anticipada a su vencimiento por decisión de CABAL o a solicitud del usuario, en este último caso notificando por escrito previamente a CABAL.
    <br/>
    <b>Artículo 13º. </b>Al vencimiento de la tarjeta prepaga, esta será́ renovada automáticamente por otro periodo de (…) años, y así́ sucesivamente, todo esto al solo criterio de CABAL, siempre y cuando no medie comunicación escrita en contrario por parte del usuario.
    <br/>
    <b>Artículo 14º.</b> La tarjeta prepaga habilita al usuario a realizar extracciones de dinero en efectivo en las terminales habilitadas al efecto y en las condiciones que CABAL determine, pudiendo ser estas solicitudes suspendidas, limitadas o reducidas a criterio exclusivo de CABAL.
    <br/>
    <br/>

    <h4 style={{color:"#1C73FE"}}>C) CONVERSIÓN, RECONVERSIÓN Y ACREDITACIÓN DE SALDOS DE LA TARJETA DIMO</h4>
    <b>Artículo 15º. </b>El usuario podrá realizar conversiones o <i>Cash In</i> y reconversiones o <i>Cash Out</i> en los puntos, locales, corresponsales, redes de cobranza y en los cajeros automáticos autorizados por CABAL.
    <br/>
    <b>Artículo 16º.</b> Los puntos de venta para giros o recepción de giros, conversiones o <i>Cash In</i> o reconversiones o <i>Cash Out</i>, estarán disponibles en los horarios y días acordados entre los mismos y CABAL, pudiendo el usuario requerir esta información en las oficinas de CABAL, desde la app DIMO, la web DIMO o llamando al *….
    <br/>
    <b>Artículo 17º.</b> CABAL determinará las modalidades de acreditación de fondos en la tarjeta prepaga DIMO, distintas a la conversión o <i>Cash In</i>, y las publicará a través de la app DIMO y la web DIMO.
    <br/><br/>
    <b>B6) RESUMEN DE TRANSACCIONES: </b>
    <br/>
    <b>Artículo 18º.</b> El usuario podrá acceder a información sobre las transacciones que ha realizado y los saldos con los que cuenta en los productos y servicios DIMO, solicitándolos a través de la app DIMO y/o la web DIMO. 
    <br/>
    <br/>

    <h4 style={{color:"#1C73FE"}}>D) SEGURIDAD:</h4>
    <b>Artículo 19º.</b> CABAL disponibilizará al usuario contraseñas automáticas para el acceso y la utilización los productos y servicios de este contrato, las cuales deberán ser cambiadas por nuevas contraseñas personales a elección del usuario.
      <br/>
      Las contraseñas personales no deben ser reveladas por el usuario a ninguna persona pues constituyen firma electrónica del usuario, la cual, a tenor de lo previsto en la Ley N° 4017/2010 <i>“De Validez Jurídica de la Firma Electrónica, la Firma Digital, los Mensajes de Datos y el Expediente Electrónico”</i>, tiene el mismo valor que su firma autógrafa, asumiendo el usuario total, plena e ilimitada responsabilidad civil, penal y administrativa por los usos que se hagan de la misma.
      <br/>
    <b>Artículo 20º.</b> En ningún caso, CABAL ni nadie en su nombre o invocando su representación, solicitarán contraseñas personales a través de llamadas telefónicas, mensajes de texto, WhatsApp o similares, correo electrónico o equivalente.
    <br/><br/>

    <h4 style={{color:"#1C73FE"}}>E) EXONERACIÓN DE RESPONSABILIDAD:</h4> 
    <b>Artículo 21º.</b> CABAL no será responsable ni podrá ser sujeto a reclamo por incumplimiento o deficiencias de los productos y servicios DIMO que sean consecuencia de problemas técnicos, caso fortuito, fuerza mayor o actos de autoridad.
     <br/>
    <b>Artículo 22º.</b> CABAL deslinda toda responsabilidad respecto de daños y perjuicios emergentes de este contrato, incluido lucro cesante, y de cualquier daño directo o indirecto, consecuencial, moral, especial o incidental alguno sea o no relacionado con la prestación del servicio.
     <br/><br/>

    <h4 style={{color:"#1C73FE"}}>F) CANCELACIÓN DEL SERVICIO Y ATENCIÓN DE RECLAMOS</h4>
    <b>Artículo 23º.</b> El usuario podrá cancelar los productos y servicios DIMO en cualquier momento y sin expresión de causa, mediante la correspondiente solicitud a ser presentada en las oficinas de CABAL.
      <br/>
      Asimismo, CABAL en cualquier momento y sin expresión de causa podrá cancelar los productos y servicios comunicando esta decisión al usuario a través de un medio físico o electrónico, incluyendo la ventana de “comunicaciones” de la app DIMO o web DIMO.
     <br/>
      En ningún caso, el presente contrato podrá ser objeto de cesión.
      <br/>
    <b>Artículo 24º.</b>  El usuario que desee presentar un reclamo relacionado al servicio, deberá hacerlo a través de la función habilitada al efecto en la app DIMO y la web DIMO, o bien comunicándose telefónicamente al (…).
     <br/><br/>

    <h4 style={{color:"#1C73FE"}}>G) PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO</h4>
    <b>Artículo 25º.</b> El usuario declara bajo fe de juramento que los bienes objeto del presente contrato no se encuentran relacionados ni serán destinados a actividades que constituyan delitos tipificados en la ley penal vigente, ni a aquellos ilícitos devenidos de la legitimación del dinero o bienes que procedan, directa o indirectamente, de cualquier actividad ilícita o delictiva.
      <br/>
      En igual sentido, el mismo manifiesta que conoce y acepta los términos de la Ley Nº 1015/97 que establece los procedimientos orientados a prevenir e impedir actos destinados a la legitimación del dinero o de bienes procedentes de actividades delictivas, así como también, lo prescripto por la Ley Nº 4024/10, la cual castiga los hechos punibles de terrorismo, asociación terrorista y financiamiento del terrorismo.
    <br/><br/>

    <h4 style={{color:"#1C73FE"}}>H) FORMA DE LOS CONTRATOS</h4>
    <b>Artículo 26º.</b> El presente contrato estará íntegramente disponible para el usuario en la app DIMO y la web DIMO, y será suscripto electrónicamente.
      <br/>
      El usuario tendrá la opción de recibir una copia completa del contrato a la dirección de correo electrónico que indique al efecto.
    <br/><br/>

    <h4 style={{color:"#1C73FE"}}>I) VIGENCIA DEL CONTRATO Y ANEXOS</h4>
    <b>Artículo 27º.</b> CABAL se reserva el derecho de modificar en cualquier momento los términos y condiciones del presente contrato y sus anexos, notificando al usuario a través de la  ventana de “comunicaciones” de la app DIMO y/o la web DIMO, con (…) días corridos de antelación a la fecha en que la modificación entrará en vigencia.
    <br/>
    <h4 style={{color:"#1C73FE"}}>J) JURISDICCIÓN Y LEY APLICABLE</h4>
      <b>Artículo 31º.</b> Para todos los efectos legales emergentes de la aplicación del presente contrato, las partes fijan sus domicilios en los lugares indicados al final del presente y se someten a la jurisdicción de los tribunales de la ciudad de Asunción del Paraguay, para lo cual queda expresamente prorrogada la competencia con renuncia a cualquier otra jurisdicción que podría corresponder.
  </p>
  <br/>

  <div style={{fontSize: "12px"}}>

    <p style={{textAlign:"center"}}> <b>ANEXO I – PRECIOS Y COMISIONES</b> </p>
    <p>Por la prestación de los servicios CABAL cobrará al usuario los siguientes precios y comisiones:</p>

    <p style={{textAlign:"center"}}> <b>ANEXO II – LÍMITES TRANSACCIONALES</b> </p>

  </div>


  </>
  )
}

export const BANNER_PUBLICIDAD_KEY = 'PANTALLA_INICIO';
export const POP_UP_LOGIN_KEY = 'LOGIN';
export const POP_UP_REGISTRO_KEY = 'PANTALLA_REGISTRO';