import React, { useEffect } from 'react';
import {checkForAndroid} from 'src/hooks/useIsAndroidInstallApp';


export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
}


function Template(props) {

  useEffect(() => {
    
    appendScript("/js/jquery-3.5.1.min.js")
    appendScript("/js/webflow.js")

  })

  return (
  <div>

  <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" className="navbar-7 w-nav">
    <div className="nav-7-container w-container">
      <a href="#" className="logo-2 w-nav-brand"><img src="images/DIMO-app-paraguay-nos-une-w.svg" width="150" alt="" className="image-15"/></a>
      <nav role="navigation" className="nav-menu-7 w-nav-menu">
        <a href="/" aria-current="page" className="nav-link-7 w-nav-link w--current" >inicio</a>
        <a href="/app" aria-current="page" className="nav-link-7 w-nav-link w-hidden-large">ingresar</a>
        <a href="/register" aria-current="page" className="nav-link-7 w-nav-link w-hidden-large">registrate</a>
        <a href="/preguntas" class="nav-link-7 w-nav-link">PREGUNTAS</a>
        <a href="https://www.cabal.coop.py/comercios" target="_blank" className="nav-link-7 w-nav-link">comercios</a>
        <a href="#contacto" className="nav-link-7 w-nav-link">contacto</a>
        <a href="/masDimo" class="nav-link-7 w-nav-link">+ DIMO</a>
      </nav>
      <a href="/login" className="login-button padding-left-20 w-hidden-medium w-hidden-small w-hidden-tiny w-button">Ingresar</a>
      <a href="/register" className="login-button-v2 w-hidden-medium w-hidden-small w-hidden-tiny w-button">Registrate</a>
      <div className="menu-button-4 w-nav-button">
        <div className="icon-2 w-icon-nav-menu"></div>
      </div>
    </div>
  </div>

  {props.children}

  <div id="contacto" class="footer-2 div-block-40">
    <div class="footer-2-container">
        <div class="row-9 w-row">
        <div class="footer-2-column w-col w-col-4 w-col-small-small-stack w-col-medium-4"><img src="images/dimo-logo-una-iniciativa-de-cabal-paraguay.svg" width="200" alt="" class="image-12" />
            <div class="social-link-wrap"><img src="images/ico-whatsapp.png" width="18" alt="" class="image-2"/>
            <a href="https://wa.me/+595217297777" target={checkForAndroid() ? "_self" : "_blank"} class="footer-link-2">(+595) 21 729 7777</a>
            </div>
            <div class="social-link-wrap"><img src="images/ico-tel.png" width="18" alt="" class="image-2" />
            <a href="tel:+595217297777" target={checkForAndroid() ? "_self" : "_blank"} class="footer-link-2">(021) 729 7777</a>
            </div>
            <div class="social-link-wrap"><img src="images/ico-email.png" width="18" alt="" class="image-2" />
            <a href="mailto:dimo@dimo.com.py" target={checkForAndroid() ? "_self" : "_blank"} class="footer-link-2">dimo@dimo.com.py</a>
            </div>
            <div class="social-link-wrap negative-ad"><img src="images/ico-gps.png" width="18" alt="" class="image-2" />
            <a href="https://goo.gl/maps/BgtvbLctKXobd3CH8" target={checkForAndroid() ? "_self" : "_blank"} class="footer-link-2">Caballero 728 c/ Luis A. de Herrera, Asunción</a>
            </div>
        </div>
        <div class="footer-2-column w-col w-col-2 w-col-small-small-stack w-col-medium-2">
            <div class="social-link-wrap"><img src="images/ico-whatsapp.png" width="18" alt="" class="image-2" />
            <a target={checkForAndroid() ? "_self" : "_blank"} href="https://wa.me/+595985729977" class="footer-link-2">Whatsapp</a>
            </div>
        </div>
        <div class="footer-2-column w-col w-col-2 w-col-small-small-stack w-col-medium-2">
            <div class="social-link-wrap"><img src="images/facebook_1facebook.png" alt="" class="image-2" />
            <a target={checkForAndroid() ? "_self" : "_blank"} href="https://www.facebook.com/DimoParaguay" class="footer-link-2">Facebook</a>
            </div>
        </div>
        <div class="footer-2-column w-col w-col-2 w-col-small-small-stack w-col-medium-2">
            <div class="social-link-wrap negative-ad"><img src="images/instagram_1instagram.png" alt="" class="image-2" />
            <a href="https://www.instagram.com/dimo.py/" target={checkForAndroid() ? "_self" : "_blank"} class="footer-link-2">Instagram</a>
            </div>
        </div>
        <div class="footer-2-column w-col w-col-2 w-col-small-small-stack w-col-medium-2"></div>
        </div>
    </div>
  </div>
  
  </div>
  
  );
}


export default Template;