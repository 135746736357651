/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  MARCAR_LIMITE_MENSUAL_TARJETA_REQUEST,
  MARCAR_LIMITE_MENSUAL_TARJETA_SUCCESS,
  MARCAR_LIMITE_MENSUAL_TARJETA_FAILURE,
  ESTADO_CUENTA_POR_PERIODO_REQUEST,
  ESTADO_CUENTA_POR_PERIODO_SUCCESS,
  ESTADO_CUENTA_POR_PERIODO_FAILURE,
  DESCARGAR_ESTADO_CUENTA_REQUEST,
  DESCARGAR_ESTADO_CUENTA_SUCCESS,
  DESCARGAR_ESTADO_CUENTA_FAILURE,
  TARJETAS_DIMO_REQUEST,
  TARJETAS_DIMO_SUCCESS,
  TARJETAS_DIMO_FAILURE,
  SEGUIMIENTO_REPOSICION_DIMO_REQUEST,
  SEGUIMIENTO_REPOSICION_DIMO_SUCCESS,
  SEGUIMIENTO_REPOSICION_DIMO_FAILURE,
  SEGUIMIENTO_IMPRESION_TARJETA_DIMO_REQUEST,
  SEGUIMIENTO_IMPRESION_TARJETA_DIMO_SUCCESS,
  SEGUIMIENTO_IMPRESION_TARJETA_DIMO_FAILURE,
  REPOSICION_TARJETA_DIMO_REQUEST,
  REPOSICION_TARJETA_DIMO_SUCCESS,
  REPOSICION_TARJETA_DIMO_FAILURE,
  REGISTRO_RELACIONAR_DIMO_PREEMITIDA_REQUEST,
  REGISTRO_RELACIONAR_DIMO_PREEMITIDA_SUCCESS,
  REGISTRO_RELACIONAR_DIMO_PREEMITIDA_FAILURE,
  REGISTRO_DIMO_PREEMITIDA_REQUEST,
  REGISTRO_DIMO_PREEMITIDA_SUCCESS,
  REGISTRO_DIMO_PREEMITIDA_FAILURE,
  REGISTRO_DIMO_REQUEST,
  REGISTRO_DIMO_SUCCESS,
  REGISTRO_DIMO_FAILURE,
  PEDIDO_IMPRESION_TARJETA_DIMO_REQUEST,
  PEDIDO_IMPRESION_TARJETA_DIMO_SUCCESS,
  PEDIDO_IMPRESION_TARJETA_DIMO_FAILURE,
  MOVIMIENTOS_DIMO_REQUEST,
  MOVIMIENTOS_DIMO_SUCCESS,
  MOVIMIENTOS_DIMO_FAILURE,
  MODIFICACION_PIN_DIMO_REQUEST,
  MODIFICACION_PIN_DIMO_SUCCESS,
  MODIFICACION_PIN_DIMO_FAILURE,
  LIMITES_TRANSACCIONES_DIMO_REQUEST,
  LIMITES_TRANSACCIONES_DIMO_SUCCESS,
  LIMITES_TRANSACCIONES_DIMO_FAILURE,
  GENERACION_PIN_DIMO_REQUEST,
  GENERACION_PIN_DIMO_SUCCESS,
  GENERACION_PIN_DIMO_FAILURE,
  COSTOS_OPERACIONALES_DIMO_REQUEST,
  COSTOS_OPERACIONALES_DIMO_SUCCESS,
  COSTOS_OPERACIONALES_DIMO_FAILURE,
  CONSULTA_SALDO_DIMO_REQUEST,
  CONSULTA_SALDO_DIMO_SUCCESS,
  CONSULTA_SALDO_DIMO_FAILURE,
  CLIENTE_DIMO_REQUEST,
  CLIENTE_DIMO_SUCCESS,
  CLIENTE_DIMO_FAILURE,
  CONSULTAR_PIN_DIMO_REQUEST,
  CONSULTAR_PIN_DIMO_SUCCESS,
  CONSULTAR_PIN_DIMO_FAILURE,
  CARGOS_APLICADOS_DIMO_REQUEST,
  CARGOS_APLICADOS_DIMO_SUCCESS,
  CARGOS_APLICADOS_DIMO_FAILURE,
  CARGO_APLICADO_DIMO_REQUEST,
  CARGO_APLICADO_DIMO_SUCCESS,
  CARGO_APLICADO_DIMO_FAILURE,
  BLOQUEO_DESBLOQUEO_DIMO_REQUEST,
  BLOQUEO_DESBLOQUEO_DIMO_SUCCESS,
  BLOQUEO_DESBLOQUEO_DIMO_FAILURE,
  AVISO_VIAJE_DIMO_REQUEST,
  AVISO_VIAJE_DIMO_SUCCESS,
  AVISO_VIAJE_DIMO_FAILURE,
} from 'src/actions/prepaidCardActions';

const initialState = {
  user: null
};

const prepaidCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case AVISO_VIAJE_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case AVISO_VIAJE_DIMO_SUCCESS: {
      const { avisoViajeDimo } = action.payload;

      return produce(state, (draft) => {
        draft.avisoViajeDimo = avisoViajeDimo;
      });
    }

    case AVISO_VIAJE_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case BLOQUEO_DESBLOQUEO_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case BLOQUEO_DESBLOQUEO_DIMO_SUCCESS: {
      const { bloqueoDesbloqueoDimo } = action.payload;

      return produce(state, (draft) => {
        draft.bloqueoDesbloqueoDimo = bloqueoDesbloqueoDimo;
      });
    }

    case BLOQUEO_DESBLOQUEO_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CARGO_APLICADO_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CARGO_APLICADO_DIMO_SUCCESS: {
      const { cargoAplicadoDimo } = action.payload;

      return produce(state, (draft) => {
        draft.cargoAplicadoDimo = cargoAplicadoDimo;
      });
    }

    case CARGO_APLICADO_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CARGOS_APLICADOS_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CARGOS_APLICADOS_DIMO_SUCCESS: {
      const { cargosAplicadosDimo } = action.payload;

      return produce(state, (draft) => {
        draft.cargosAplicadosDimo = cargosAplicadosDimo;
      });
    }

    case CARGOS_APLICADOS_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CLIENTE_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CLIENTE_DIMO_SUCCESS: {
      const { clienteDimo } = action.payload;

      return produce(state, (draft) => {
        draft.clienteDimo = clienteDimo;
      });
    }

    case CLIENTE_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CONSULTAR_PIN_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTAR_PIN_DIMO_SUCCESS: {
      const { consultarPinDimo } = action.payload;

      return produce(state, (draft) => {
        draft.consultarPinDimo = consultarPinDimo;
      });
    }

    case CONSULTAR_PIN_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CONSULTA_SALDO_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTA_SALDO_DIMO_SUCCESS: {
      const { consultaSaldoDimo } = action.payload;

      return produce(state, (draft) => {
        draft.consultaSaldoDimo = consultaSaldoDimo;
      });
    }

    case CONSULTA_SALDO_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case COSTOS_OPERACIONALES_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case COSTOS_OPERACIONALES_DIMO_SUCCESS: {
      const { costosOperacionalesDimo } = action.payload;

      return produce(state, (draft) => {
        draft.costosOperacionalesDimo = costosOperacionalesDimo;
      });
    }

    case COSTOS_OPERACIONALES_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case DESCARGAR_ESTADO_CUENTA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case DESCARGAR_ESTADO_CUENTA_SUCCESS: {
      const { descargarEstadoCuenta } = action.payload;

      return produce(state, (draft) => {
        draft.descargarEstadoCuenta = descargarEstadoCuenta;
      });
    }

    case DESCARGAR_ESTADO_CUENTA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case ESTADO_CUENTA_POR_PERIODO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case ESTADO_CUENTA_POR_PERIODO_SUCCESS: {
      const { estadoCuentaPorPeriodo } = action.payload;

      return produce(state, (draft) => {
        draft.estadoCuentaPorPeriodo = estadoCuentaPorPeriodo;
      });
    }

    case ESTADO_CUENTA_POR_PERIODO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case GENERACION_PIN_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case GENERACION_PIN_DIMO_SUCCESS: {
      const { generacionPinDimo } = action.payload;

      return produce(state, (draft) => {
        draft.generacionPinDimo = generacionPinDimo;
      });
    }

    case GENERACION_PIN_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LIMITES_TRANSACCIONES_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case LIMITES_TRANSACCIONES_DIMO_SUCCESS: {
      const { limitesTransaccionesDimo } = action.payload;

      return produce(state, (draft) => {
        draft.limitesTransaccionesDimo = limitesTransaccionesDimo;
      });
    }

    case LIMITES_TRANSACCIONES_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case MARCAR_LIMITE_MENSUAL_TARJETA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case MARCAR_LIMITE_MENSUAL_TARJETA_SUCCESS: {
      const { marcarLimiteMensualTarjeta } = action.payload;

      return produce(state, (draft) => {
        draft.marcarLimiteMensualTarjeta = marcarLimiteMensualTarjeta;
      });
    }

    case MARCAR_LIMITE_MENSUAL_TARJETA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case MODIFICACION_PIN_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case MODIFICACION_PIN_DIMO_SUCCESS: {
      const { modificacionPinDimo } = action.payload;

      return produce(state, (draft) => {
        draft.modificacionPinDimo = modificacionPinDimo;
      });
    }

    case MODIFICACION_PIN_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case MOVIMIENTOS_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case MOVIMIENTOS_DIMO_SUCCESS: {
      const { movimientosDimo } = action.payload;

      return produce(state, (draft) => {
        draft.movimientosDimo = movimientosDimo;
      });
    }

    case MOVIMIENTOS_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case PEDIDO_IMPRESION_TARJETA_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case PEDIDO_IMPRESION_TARJETA_DIMO_SUCCESS: {
      const { pedidoImpresionTarjetaDimo } = action.payload;

      return produce(state, (draft) => {
        draft.pedidoImpresionTarjetaDimo = pedidoImpresionTarjetaDimo;
      });
    }

    case PEDIDO_IMPRESION_TARJETA_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case REGISTRO_DIMO_PREEMITIDA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case REGISTRO_DIMO_PREEMITIDA_SUCCESS: {
      const { registroDimoPreemitida } = action.payload;

      return produce(state, (draft) => {
        draft.registroDimoPreemitida = registroDimoPreemitida;
      });
    }

    case REGISTRO_DIMO_PREEMITIDA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case REGISTRO_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case REGISTRO_DIMO_SUCCESS: {
      const { registroDimo } = action.payload;

      return produce(state, (draft) => {
        draft.registroDimo = registroDimo;
      });
    }

    case REGISTRO_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case REGISTRO_RELACIONAR_DIMO_PREEMITIDA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case REGISTRO_RELACIONAR_DIMO_PREEMITIDA_SUCCESS: {
      const { registroRelacionarDimoPreemitida } = action.payload;

      return produce(state, (draft) => {
        draft.registroRelacionarDimoPreemitida = registroRelacionarDimoPreemitida;
      });
    }

    case REGISTRO_RELACIONAR_DIMO_PREEMITIDA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case REPOSICION_TARJETA_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case REPOSICION_TARJETA_DIMO_SUCCESS: {
      const { reposicionTarjetaDimo } = action.payload;

      return produce(state, (draft) => {
        draft.reposicionTarjetaDimo = reposicionTarjetaDimo;
      });
    }

    case REPOSICION_TARJETA_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_IMPRESION_TARJETA_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_IMPRESION_TARJETA_DIMO_SUCCESS: {
      const { seguimientoImpresionTarjetaDimo } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoImpresionTarjetaDimo = seguimientoImpresionTarjetaDimo;
      });
    }

    case SEGUIMIENTO_IMPRESION_TARJETA_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_REPOSICION_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_REPOSICION_DIMO_SUCCESS: {
      const { seguimientoReposicionDimo } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoReposicionDimo = seguimientoReposicionDimo;
      });
    }

    case SEGUIMIENTO_REPOSICION_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case TARJETAS_DIMO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case TARJETAS_DIMO_SUCCESS: {
      const { tarjetasDimo } = action.payload;

      return produce(state, (draft) => {
        draft.tarjetasDimo = tarjetasDimo;
      });
    }

    case TARJETAS_DIMO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    default: {
      return state;
    }
  }
};

export default prepaidCardReducer;
