import React, {
  useEffect,
  useState
} from 'react';

import GAException from 'src/components/GAException';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';

import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import Cookies from 'js-cookie';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  const { saveSettings } = useSettings();

  const setDefaultDimoTheme = () => {
    saveSettings({ theme: THEMES.LIGHT });
    Cookies.set('settingsUpdated', 'true');
  };

  useEffect(() => {
    const initAuth = async () => {
/*      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });*/

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        const user = await authService.loginInWithToken();
        await dispatch(setUserData(user));
      }

      setLoading(false);

      setDefaultDimoTheme();

    };

    initAuth();
    // eslint-disable-next-line
  }, [dispatch]);


  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
