import _ from "lodash";

import accountService from "src/services/accountService";
import authService, {toUserLoginData} from "src/services/authService";
import { toCreditCardData } from "src/services/creditCardService";
import { toFacturadorData, toPaymentServiceDataItemService } from "src/services/paymentService";

const getUser = () => {
  if (authService.getAccessToken() === null)
    return null;
  else
    return authService.getUserData();
}

export function getTipoActividad(pathname) {
  let v_tipo = 'Otros';
  let v_pathname = pathname + "";

  if (v_pathname.includes('/register')) {
    v_tipo = 'Registro';
  } else if (v_pathname.includes('/app/payment')) {
    v_tipo = 'Pagos';
  } else if (v_pathname.includes('/app/compras/qr')) {
    v_tipo = 'ComprasQR';
  } else if (v_pathname.includes('/app/transferencias')) {
    v_tipo = 'Transferencias';
  } else if (v_pathname.includes('/app/cooperativas')) {
    v_tipo = 'Cooperativas';
  } else if (v_pathname.includes('/app/transaccion/resultado')) {
    v_tipo = 'Resultado';
  } else if (v_pathname.includes('/app/transaccion/comprobante')) {
    v_tipo = 'Comprobante';
  } else if (v_pathname.includes('/app/prepaidcard')) {
    v_tipo = "TarjetaPrepaga";
  } else if (v_pathname.includes('/app/creditcard')) {
    v_tipo = 'TarjetaCredito';
  } else if (v_pathname.includes('/app/recargasaldo')) {
    v_tipo = 'RecargaSaldo';
  } else if (v_pathname.includes('/app/gestion-efectivo')) {
    v_tipo = 'OperacionesQR';
  } else if (v_pathname.includes('/app/generar/qr')) {
    v_tipo = 'GenerarQR';
  } else if (v_pathname.includes('/app/generar/qr')) {
    v_tipo = 'LeerQR';
  } else if (v_pathname.includes('/app/account')) {
    v_tipo = 'PerfilUsuario';
  } else if (v_pathname === '/app/completar/registro') {
    v_tipo = 'CompletarRegistro';
  } else if (v_pathname === '/app' || v_pathname === '/app/') {
    v_tipo = 'Inicio';
  }
  return v_tipo;
}

export function getData(pathname, data) {
  let v_data = [];
  let v_pathname = pathname + "";
  let v_dataObj = (data !== null && data !== undefined) ? data : {};

  try {
    const v_userData = getUser() ;

    if (v_userData != null && v_userData != undefined) {
      v_data = v_data.concat([
        {
          key: "dniNumber",
          value: v_userData.document,
        },
        {
          key: "firstName",
          value: v_userData.firstName,
        },
        {
          key: "lastName",
          value: v_userData.lastName,
        },
        {
          key: "email",
          value: v_userData.email,
        },
        {
          key: "phone",
          value: v_userData.phone,
        },
      ])
    }

    // Detalle de Pagos
    if (v_pathname.includes('/app/payment/detail')) {
      const serviceData = toPaymentServiceDataItemService(v_dataObj.serviceData)
      const facturadorData = toFacturadorData(v_dataObj.facturador);
      v_data = v_data.concat([
        {
          key: "entidadPago",
          value: facturadorData.paymentEntityName
        },
        {
          key: "servicioPago",
          value: (serviceData.paymentServiceName != null && serviceData.paymentServiceName != undefined) ? serviceData.paymentServiceName : ""
        }
      ]);
    } else if (v_pathname.includes('/register')) {

      const registerData = data;

      Object.keys(registerData).forEach(function(key){

        v_data.push({
          key: ("registerData."+key+""),
          value: (registerData[key]+"")
        })
      })

    } else if (
      v_pathname.includes('app/transaccion/resultado') ||
      v_pathname.includes('/app/payment/resultado') ||
      v_pathname.includes('/app/payment/cooperativa/resultado') ||
      v_pathname.includes('app/payment/creditCard/resultado')
    ) {
      const { status, transferNoDIMO, response, request } = v_dataObj.data;

      v_data = v_data.concat([
        {
          key: "resultado",
          value: status === true ? "exito" : (status === false ? "rechazo" : "indefinido")
        },
        {
          key: "response",
          value: JSON.stringify(response)
        }
      ]);
    }
    else if (v_pathname.includes('/app/creditcard/vincularDimoTC')) {
      const creditCard = toCreditCardData(v_dataObj.creditCard);

      v_data = v_data.concat([
        {
          key: "tarjeta",
          value: creditCard.TAR_MASK
        },
        {
          key: "emisor",
          value: creditCard.EMISOR + " - " + creditCard.DESC_EMISOR
        },
      ]);

    }
    else {
      if (typeof v_dataObj === "object") {

        Object.keys(v_dataObj).forEach(function(key){

          const _objectPropValue = typeof v_dataObj[key] === "object" ?
                                   JSON.stringify(v_dataObj[key]) : (v_dataObj[key] +"");

          v_data.push({
            key: (key+""),
            value: _objectPropValue
          })
        })
      }
    }


  } catch (e) { }
  return JSON.stringify(v_data);

}

function registrarActividad(v_tipoActividad, vPathname, vData) {

  let data = null;
  let pathname = null;

  pathname = vPathname;
  data = vData;
  localStorage.setItem("ultimoTipoActividad", v_tipoActividad);
  localStorage.setItem("ultimaPaginaActiva", vPathname);

  accountService.registrarActividadFrontend(v_tipoActividad, vPathname, getData(vPathname, vData));

  return { pathname: pathname, data: data };
}

export function registrarActividadDimo(vPathname, vData, registrar) {

  const ultimoTipoActividad = localStorage.getItem("ultimoTipoActividad");
  const ultimaPaginaActiva = localStorage.getItem("ultimaPaginaActiva");

  try {
    let v_tipoActividad = getTipoActividad(vPathname);
    v_tipoActividad = v_tipoActividad === "Resultado" ? ultimoTipoActividad : v_tipoActividad;

    if (ultimaPaginaActiva !== vPathname && vPathname != null && vPathname != "") {
      return registrarActividad(v_tipoActividad, vPathname, vData);
    } else if (registrar) {
      return registrarActividad(v_tipoActividad, vPathname, vData);
    } else {
      let data = null;
      let pathname = null;

      pathname = vPathname;
      data = vData;
      return { pathname: pathname, data: data };
    }
  } catch (e) {

  }

}

function useRegistrarActividadDimo(vPathname, vData) {
  return registrarActividadDimo(vPathname, vData);
}

export default useRegistrarActividadDimo;
