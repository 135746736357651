import axios from '../utils/axios';

import { payment_service_api_url as API_URL } from 'src/services/backendApiService';
import transactionService from './transactionService';

let BODY_HEADER = {

}

const direct_api_tar = false;

if (direct_api_tar) {
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: "102",
    idRequest: "123456",
    codigoEF: "csoto",
    canal: "102",
    idClienteEF: "prueba",
    ciCliente: "csoto"
  }
}
else {
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion: "1.0.2",
    token: "prueba",
    user: "csoto"
  }
}

export const toFacturadorData = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {}

  let facturador = {
    paymentEntityCode: item.hasOwnProperty("paymentEntityCode") ? item.paymentEntityCode : "",
    paymentEntityLogo: item.hasOwnProperty("paymentEntityLogo") ? item.paymentEntityLogo : "",
    paymentEntityName: item.hasOwnProperty("paymentEntityName") ? item.paymentEntityName : "",
    paymentServices: (item.hasOwnProperty("paymentServices") && Array.isArray(item.paymentServices)) ? item.paymentServices : [],
  };

  return facturador;
}

export const toPaymentServiceData = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {}

  let facturador = {
    isSICOOP: item.hasOwnProperty("isSICOOP") && item.isSICOOP === true ? true : false,
    paymentEntityCode: item.hasOwnProperty("paymentEntityCode") ? item.paymentEntityCode : "",
    paymentEntityLogo: item.hasOwnProperty("paymentEntityLogo") ? item.paymentEntityLogo : "",
    paymentEntityName: item.hasOwnProperty("paymentEntityName") ? item.paymentEntityName : "",
    paymentEntityDestAccountType: item.hasOwnProperty("paymentEntityDestAccountType") ? (item.paymentEntityDestAccountType+"") : "",
    paymentServices: (item.hasOwnProperty("paymentServices") && Array.isArray(item.paymentServices)) ? item.paymentServices : [],
  };

  return facturador;
}

export const toPaymentServiceDataItemService = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {}

  let paymentService = {
    network: item.hasOwnProperty("network") ? item.network : "",
    networkParticipantCode: item.hasOwnProperty("networkParticipantCode") ? item.networkParticipantCode : "",
    invokeCommission: item.hasOwnProperty("invokeCommission") && item.invokeCommission === true,
    paymentEntityId: item.hasOwnProperty("paymentEntityId") ? item.paymentEntityId : "",
    paymentServiceId: item.hasOwnProperty("paymentServiceId") ? item.paymentServiceId : "",
    paymentServiceName: item.hasOwnProperty("paymentServiceName") ? item.paymentServiceName : "",
  };

  return paymentService;
}

export const toPaymentServiceParameterData = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {}

  let paymentParameter = {
    service: item.hasOwnProperty("service") ? item.service : null,
    tipoTrx: item.hasOwnProperty("tipoTrx") ? item.tipoTrx : "",
    parameter: (item.hasOwnProperty("parameter") && Array.isArray(item.parameter)) ? Array.from(item.parameter) : [],

    acceptsPartialPayment: item.hasOwnProperty("acceptsPartialPayment") && item.acceptsPartialPayment === true ? true : false,
    temporaryIdentification: item.hasOwnProperty("temporaryIdentification") && item.temporaryIdentification === true ? true : false,
    temporyJson: item.hasOwnProperty("temporyJson") ? item.temporyJson : "[]",

    useValidationRegexp: item.hasOwnProperty("useValidationRegexp") && item.useValidationRegexp === true ? true : false

  };

  return paymentParameter;
}

export const toPaymentServiceParameterItemPrm = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {}

  let paymentParameter = {
    fieldType: item.hasOwnProperty("fieldType") ? item.fieldType : null,
    label: item.hasOwnProperty("label") ? item.label : "",
    name: item.hasOwnProperty("name") ? item.name : "",
    notificationOrder: item.hasOwnProperty("notificationOrder") ? item.notificationOrder : null,
    options: item.hasOwnProperty("options") ? item.options : "",
    reference: item.hasOwnProperty("reference") && item.reference === true ? true : false,
    size: item.hasOwnProperty("size") ? item.size : null,
    title: item.hasOwnProperty("title") ? item.title : "",
    type: item.hasOwnProperty("type") ? item.type : "",

    hint: item.hasOwnProperty("hint") ? (item.hint+""): "",
    errorMessage: item.hasOwnProperty("errorMessage") ? item.errorMessage : "",
    validationRegexp: item.hasOwnProperty("validationRegexp")   &&
                      item.validationRegexp !== null            &&
                      item.validationRegexp !== "null"          &&
                      (item.validationRegexp+"").trim() !== ""
                      ?
                      (item.validationRegexp+"") : "",
  };

  //paymentParameter.validationRegexp = paymentParameter.validationRegexp.replace(/\(\d*\)|\/\(P\)\//g, "");

  /*
  if (paymentParameter.validationRegexp.substr(0,1) === "/") {
    paymentParameter.validationRegexp = paymentParameter.validationRegexp.substr(1);
  }

  console.log("valid1st", paymentParameter.validationRegexp.substr(paymentParameter.validationRegexp.length-1,1));
  console.log("valid2nd", paymentParameter.validationRegexp.substr(0, paymentParameter.validationRegexp.length-1));

  if (paymentParameter.validationRegexp.substr(paymentParameter.validationRegexp.length-1,1) === "/") {
    paymentParameter.validationRegexp = paymentParameter.validationRegexp.substr(0, paymentParameter.validationRegexp.length-1);
  }*/

  return paymentParameter;
}

export const toPaymentServiceDataList = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {};

  let paymentDataList = {
    entity: item.hasOwnProperty("entity") ? item.entity : "",
    label: toPaymentServiceDataListLabel(item.label),
    parameter: (item.hasOwnProperty("parameter") && Array.isArray(item.parameter)) ? item.parameter : [],
    service: item.hasOwnProperty("service") ? item.service : null,
    tipoTrx: item.hasOwnProperty("tipoTrx") ? item.tipoTrx : "",
  };

  return paymentDataList;

}

export const toPaymentServiceDataListLabel = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {};

  let paymentDataListLabel = {
    name: item.hasOwnProperty("name") ? item.name : "",
    title: item.hasOwnProperty("title") ? item.title : "",
    value: item.hasOwnProperty("value") ? item.value : "",
  }

  return paymentDataListLabel;
}

export const toPaymentServiceDataListItemPrm = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {};

  let paymentDataListItemPrm = {
    editable: item.hasOwnProperty("editable") && item.editable === true ? true : false,
    fieldType: item.hasOwnProperty("fieldType") ? item.fieldType : null,
    format: item.hasOwnProperty("format") ? item.format : "",
    invoiceGroup: item.hasOwnProperty("invoiceGroup") ? item.invoiceGroup : null,
    invoiceHeader: item.hasOwnProperty("invoiceHeader") ? (item.invoiceHeader+"") : "",
    isAccount: item.hasOwnProperty("isAccount") && item.isAccount === true ? true : false,
    isAmount: item.hasOwnProperty("isAmount") && item.isAmount === true ? true : false,
    isDoc: item.hasOwnProperty("isDoc") && item.isDoc === true ? true : false,
    label: item.hasOwnProperty("label") ? item.label : "",
    name: item.hasOwnProperty("name") ? item.name : "",
    notificationOrder: item.hasOwnProperty("notificationOrder") ? item.notificationOrder : null,
    options: item.hasOwnProperty("options") ? item.options : "",
    title: item.hasOwnProperty("title") ? item.title : "",
    type: item.hasOwnProperty("type") ? item.type : "",
    value: item.hasOwnProperty("value") ? item.value : "",
  }

  return paymentDataListItemPrm;
}

export const toPaymentCommissionData = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {};

  let paymentCommissions = {
    commissionTc: item.hasOwnProperty("commissionTc") && !isNaN(item.commissionTc) ? Number(item.commissionTc) : 0,
    commissionTd: item.hasOwnProperty("commissionTd") && !isNaN(item.commissionTd) ? Number(item.commissionTd) : 0,
  }

  return paymentCommissions;
}

export const toDatoComprobanteRedPagos = (dataPrm) => {
  const item = (dataPrm && dataPrm !== null) ? dataPrm : {};

  let datoComprobante = {
    code: item.hasOwnProperty("code") ? (item.code+"") : "",
    name: item.hasOwnProperty("name") ? (item.name+"") : "",
    value: item.hasOwnProperty("value") ? (item.value+"") : "",
    isAmount: item.hasOwnProperty("isAmount") && item.isAmount === true ? true : false,
    order: item.hasOwnProperty("order") ? (item.order+"") : "",
  }

  return datoComprobante;
}

class PaymentService {

  defaultResponseError = (msgError, msgErrorAlt) => {

    const msgErrorAlternativo = (msgErrorAlt && msgErrorAlt !== null && msgErrorAlt !== undefined && typeof msgErrorAlt === 'string') ?
                                msgErrorAlt :
                                'Ocurrió un error inesperado'
                                ;

    const responseDefault = {
      error: {
        message: (msgError && msgError !== null && msgError !==  undefined && typeof msgError === 'string') ? msgError : msgErrorAlternativo
      }
    }

    return responseDefault;
  }

  toDefaultResponseError = (itemError, msgErrorAlt) => {

    let msgError = (msgErrorAlt && msgErrorAlt !== null && msgErrorAlt !== undefined && typeof msgErrorAlt === 'string') ?
                    msgErrorAlt : 'Ocurrió un error inesperado' ;
    if (
      itemError &&
      itemError !== null &&
      itemError !== undefined &&
      itemError.hasOwnProperty("error") &&
      itemError.error.hasOwnProperty("message") &&
      itemError.error.message !== ""
    ) {
      msgError = itemError.error.message;
    }

    const responseDefault = {
      error: {
        message: msgError
      }
    }

    return responseDefault;
  }

  getServicePaymentList = () => new Promise((resolve, reject) => {
    axios.post(API_URL + '/GetPaymentServiceList', {})
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getServicePaymentListByFilter = (offset, limit, search) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/v2/GetPaymentServiceListByFilter`, {
      offset,
      limit,
      search
    })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve({ data: response.data.data, total: response.data.total });
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  getServicePaymentParameter = (dataPrm) => new Promise((resolve, reject) => {

    let request = {
      request: {
        service: dataPrm.hasOwnProperty("service") ? dataPrm.service : null,
        entity: dataPrm.hasOwnProperty("entity") ? dataPrm.entity : null,
      }
    }

    axios.post(API_URL + '/GetPaymentServiceParameterList', request)
      .then((response) => {
        if (response.data.header.codReturn.trim() === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getServicePaymentDataList = (request) => new Promise((resolve, reject) => {

    axios.post(API_URL + '/GetPaymentServiceDataList', request)
      .then((response) => {
        if (response.data.header.codReturn.trim() === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getMinuteRechargeCompanys = () => new Promise((resolve, reject) => {

    let request = {
      request: {
        configurationId: "MINUTESRECHARGE"
      }
    }

    axios.post(API_URL + '/ConfigurationServices', request)
      .then((response) => {
        if (response.data.response.header.codReturn === '0') {
          if(response.data.response.data.configurationValue !== ''){
            resolve(JSON.parse(response.data.response.data.configurationValue));
          }
        } else {
          reject({ error: { message: response.data.response.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getOtherWalletCompanys = () => new Promise((resolve, reject) => {

    let request = {
      request: {
        configurationId: "WALLETTRANSFER"
      }
    }

    axios.post(API_URL + '/ConfigurationServices', request)
      .then((response) => {
        if (response.data.response.header.codReturn === '0') {
          if(response.data.response.data.configurationValue !== ''){
            resolve(JSON.parse(response.data.response.data.configurationValue));
          }
        } else {
          reject({ error: { message: response.data.response.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getPaymentCommissions = (dataPrm) => new Promise((resolve, reject) => {

    let request = {
      request: {
        service: dataPrm.hasOwnProperty("service") ? dataPrm.service : null,
        amount: dataPrm.hasOwnProperty("amount") ? dataPrm.amount : null,
        network: dataPrm.hasOwnProperty("network") ? dataPrm.network : null,
        temporaryIdentification: dataPrm.hasOwnProperty("temporaryIdentification") ? dataPrm.temporaryIdentification : null,
        parameter: dataPrm.hasOwnProperty("parameter") ? dataPrm.parameter : null,
      }
    }

    axios.post(API_URL + '/GetPaymentCommissions', request)
      .then((response) => {
        if (response.data.header.codReturn.trim() === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getPaymentDatosComprobante = (codigoUuid) => new Promise((resolve, reject) => {

    let request = {
      request: {
        codigoUuid: codigoUuid ? codigoUuid : null,
      }
    }

    axios.post(API_URL + '/GetPaymentTicket', request)
      .then((response) => {
        if (response.data.header.codReturn.trim() === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        reject(error);

        // Test Only
        const testArray = Array.from(Array(8)).map((_,i) => {
          let datoTest = toDatoComprobanteRedPagos();
          let index = i+1;
          datoTest.code = "["+index+"]";
          datoTest.name = "Data Red Nº "+index;
          datoTest.value = "Random value "+index;
          datoTest.isAmount = false;

          if (i == 7) {
            datoTest.name = "Monto";
            datoTest.value = "2500000";
            datoTest.isAmount = true;
          }

          return datoTest;
        })

        resolve(testArray);

      });
  })

}

const paymentService = new PaymentService();

export default paymentService;
