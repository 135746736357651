import axios from '../utils/axios';
import GAException from 'src/components/GAException';

import { operations_service_api_url as API_URL } from 'src/services/backendApiService';

//const API_URL = 'http://localhost:8080/api/paymentEngine/v1';
//const API_URL = backendApiService.getPaymentEngineApiUrl();

let BODY_HEADER = {
  appVersion: "1.0",
  imei: "",
  token: "",
  user: "",
  phoneNumber: ""
}

let BODY_HEADER_REG_QRPROMO = {
  appVersion: "1.0",
  token: "",
  user: "",
}

const HEADERS = () => {
  const headers = {
    'Content-Type': 'application/json',
    //'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
  }

  return headers;
}

export const toResponseReadQR=(qrData)=> {
  const item = (qrData && qrData !== null) ? qrData : {};

  let qrRead = {
    idQr: item.hasOwnProperty("idQr") ? item.idQr : "",
    codMoneda: item.hasOwnProperty("codMoneda") ? item.codMoneda : "",
    importe: (item.hasOwnProperty("importe") && !isNaN(item.importe)) ? Number(item.importe) : 0,
    glosa: item.hasOwnProperty("tipoCuentaDestino") ? item.tipoCuentaDestino : "",
    fechaVencimiento: item.hasOwnProperty("fechaVencimiento") ? item.fechaVencimiento : "",
    numPagos: item.hasOwnProperty("numPagos") ? item.numPagos : "",
    codServicio: item.hasOwnProperty("codServicio") ? item.codServicio : "",
    codEf: item.hasOwnProperty("codDestinatario") ? item.codDestinatario : "",
    cuentaAbono: item.hasOwnProperty("numCuentaDestino") ? item.numCuentaDestino : "",
    ciNitDestino: item.hasOwnProperty("docDestinatario") ? item.docDestinatario : "",
    titularDestino: item.hasOwnProperty("titularDestinatario") ? item.titularDestinatario : "",
    tipoQr: item.hasOwnProperty("tipoQr") ? item.tipoQr : "",
    origenQr: item.hasOwnProperty("origenQr") ? item.origenQr : "",
    // Compras Adicionales para compras en COMERCIOS
    docUsuario: item.hasOwnProperty("docUsuario") ? item.docUsuario : "",
    codComercio: item.hasOwnProperty("codComercio") ? item.codComercio : "",
    idTransaccionQr: item.hasOwnProperty("idTransaccionQr") ? item.idTransaccionQr : "",
    codOperacion: item.hasOwnProperty("codOperacion") ? item.codOperacion : "",
    idOperacionCaja: item.hasOwnProperty("idOperacionCaja") ? item.idOperacionCaja : "",
    cuotas: item.hasOwnProperty("cuotas") ? item.cuotas : "",
    codDestinatario: (item.hasOwnProperty("codDestinatario") && item.codDestinatario !== null) ? item.codDestinatario
      : item.hasOwnProperty("codOriginante") ? item.codOriginante : '',
    info: item.hasOwnProperty("info") ? item.info : "",

  }
  if(item.hasOwnProperty('info')) {
    item.info.forEach( (x) => {
      if (x.key === 'orgId') {
        qrRead.orgId = x.value;
      }
      if (x.key === 'terminalDsc') {
        qrRead.terminalDsc = x.value;
      }
      if (x.key === 'terminalId') {
        qrRead.terminalId = x.value;
      }
      if (x.key === 'timestamp') {
        qrRead.timestamp = x.value;
      }
    })
  }

  return qrRead;

}

export const toResultadoIngresoPromoQR=(data)=> {
  const item = (data && data !== null) ? data : {};

  let dataRetorno = {
    registroExitoso: item.hasOwnProperty("registroExitoso") ? item.registroExitoso : false,
    registroPrevio: item.hasOwnProperty("registroPrevio") ? item.registroPrevio : false,
    descripcionPromocion: item.hasOwnProperty("descripcionPromocion") ? item.descripcionPromocion : "",
    mensaje: item.hasOwnProperty("mensaje") ? item.mensaje : '',
  }

  return dataRetorno;
}

class QrService {

  generateQR = (qrCobro) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/qr/simple/generarQr', { header: BODY_HEADER, qrCobro: qrCobro }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  getQrByCI = (param) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/qr/obtener-hash/documento', { header: BODY_HEADER, data: param }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data.hash);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  readQR = (qrCobro, ef, documentoNro) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v3/qr/simple/leerQr', { header: BODY_HEADER, qrCobro: qrCobro, ef: ef, documento: documentoNro}, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codRespuesta === '0' && response.data.data.codRespuesta === '0') {
          resolve(response.data.data);
        } else if (response.data.header.codRespuesta === '0' && response.data.data.codRespuesta !== '0') {
          reject({ error: { message: response.data.data ? response.data.data.txtRespuesta : response.data.header.txtReturn } });
        } else {
          reject({ error: { message: response.data.header.txtRespuesta } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  comprarTicket= (requestTicket) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/qr/ticket/comprar', { header: BODY_HEADER, data: requestTicket }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  listarTickets= (requestTicket) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/qr/ticket/listar', { header: BODY_HEADER, data: requestTicket }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  ingresarPromocionQR = (data) => new Promise((resolve, reject) => {

    const dataPrm = {
      idQr: data.hasOwnProperty("idQr") ? data.idQr : "",
    }

    axios.post(API_URL + '/v2/agregarPromocion', { header: BODY_HEADER_REG_QRPROMO, data: dataPrm }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve({ registroExitoso: true, registroPrevio: false, descripcionPromocion: response.data.data.descripcionPromocion, mensaje: response.data.data.mensaje});
        } else if (response.data.header.codReturn === '100033') { // Ya estas participando
          resolve({ registroExitoso: false, registroPrevio: true, descripcionPromocion: response.data.data.descripcionPromocion, mensaje: response.data.data.mensaje});
        } else if (response.data.header.codReturn === '100032') { // Promo No existe
          resolve({ registroExitoso: false, registroPrevio: false, descripcionPromocion: "", mensaje: response.data.header.txtReturn});
        }
        else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });


}

const qrService = new QrService();

export default qrService;
