import React from 'react';

import GAException from 'src/components/GAException';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import Template from './Template';
import Home from './Home';


const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Dimo Paraguay"
    >
      <Template>
        <Home />
      </Template>
      {/*
      <Hero />
      <Features />
      <Testimonials />
      <CTA />
      <FAQS />

      */}

    </Page>
  );
}

export default HomeView;
