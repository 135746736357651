import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  makeStyles,
} from '@material-ui/core';

import NavBar from './NavBar';
import TopBar from './TopBar';
import BottomBar from './BottomBar';

import { useLocation } from 'react-router';
import useRegistrarActividadDimo from 'src/hooks/useRegistrarActividadDimo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    paddingBottom: 30,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 299
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: '50px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: '1306',
  },
  bottomButton: {
    position: 'absolute',
    zIndex: '1302',
    height: '50px',
    bottom: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  contenFooter: {
    width: '100%',
    height: '90px',
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isShowDialog, setShowDialog] = useState(false);
  const [isMainPage, setMainPage] = useState(false);

  useRegistrarActividadDimo(location.pathname, location.state);

  const handleFloatButton = () => {
    setShowDialog(!isShowDialog);
  }

  useEffect(() => {
    if (location.pathname === '/app' || location.pathname === '/app/') {
      setMainPage(true)
    } else {
      setMainPage(false)
    }
  }, [location.pathname])

  return (
    <div className={classes.root}>
      {
        //isShowDialog ? <DirectAccess isShowDialog={(value) => setShowDialog(value)} /> : null
      }
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      {
        /*!isShowDialog
          ? <Fab
            aria-label="action"
            className={classes.fab}
            onClick={handleFloatButton}
          >
            <img
              style={{ width: '100%' }}
              alt="float_button"
              src={"/static/images/dimo/float_plus_button.png"}
            />
          </Fab>
          : <Fab
            aria-label="action"
            className={classes.fab}
            onClick={handleFloatButton}
          >
            <img
              style={{ width: '100%' }}
              alt="float_minus_button"
              src={"/static/images/dimo/float_minus_button.png"}
            />
          </Fab>
          */
      }
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
      <footer
        className={classes.bottomButton}
        //style={isMainPage ? {} : { display : 'none' }}
      >
        <BottomBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={!isMobileNavOpen}
        />
      </footer>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
