import React, { useCallback, useState } from 'react';

import clsx from 'clsx';
import PropTypes, { element } from 'prop-types';
import { Box, Button, ListItem, makeStyles, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import SplashScreen from 'src/components/SplashScreen';
import functionalitiesService, {
  funcionalidades,
  msgOpcionNoDisponible
} from 'src/services/functionalitiesService';
import getOS from '../../../hooks/getOS';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import qrService, { toResponseReadQR } from '../../../services/qrService';
import GAException from '../../../components/GAException';
import * as elem from 'history/DOMUtils';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: 'auto',
  },
  button: {
    color: '#fff',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    //width: '100%'
  },
  buttonLeaf: {
    color: '#fff',
    padding: '0',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    //width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    minWidth: 0
  },
  icon: {
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.tertiary.main,
  },
  iconLabel: {
    color: theme.palette.tertiary.main,
    fontSize: '10px',
  },
  endIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.tertiary.main,
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: '#fff',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.tertiary.main,
    }
  }
}));

function BottomItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  endIcon: EndIcon,
  className,
  open: openProp,
  info: Info,
  onLogoutClick: onLogoutClick,
  ...rest
}) {
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState((getOS() === 'Android'));
  const [isMobileIos, setIsMobileIos] = useState((getOS() === 'iPhone'));
  const [appVersion, setAppVersion] = useState(localStorage.getItem('appVersion'));
  const [isloadingEventListener, setIsloadingEventListener] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user } = useSelector((state) => state.account);
  const [isLoading, setLoading] = useState(false);

  const readQrfunction = useCallback(
    (event) => {
      //pasar a Json
      let parseQr = JSON.parse(event);

      try {
        setLoading(true);

        // console.log('qrCobro', qrCobro);
        const tpdimo = JSON.parse(localStorage.getItem('tpdimo'));
        qrService.readQR(parseQr.detail, '', parseInt(tpdimo.DOCUMENTO, 10))
          .then((response) => {
            setLoading(false);
            const qrReadData = toResponseReadQR(response);

            // console.log('response', JSON.stringify(response,null,2));
            // console.log('qrReadData', JSON.stringify(qrReadData,null,2));

            const data = {};

            const importeQR = qrReadData.importe / 100;

            data.titularName = qrReadData.titularDestino; // Nombre Comercial, para compras con QR
            data.accountNumber = qrReadData.cuentaAbono; // Codigo de Comercio, para compras con QR
            data.amount = importeQR;
            data.info = qrReadData.info;

            if (qrReadData.glosa === 'CCAHOD') {
              data.accountType = 'QRCOOP'; // QRCOOP
            }
            if (qrReadData.glosa === 'TCD') {
              data.accountType = 'QRTC'; // QRTC
            }
            if (qrReadData.glosa === 'TPD') {
              data.accountType = 'QRTP'; // QRTP
            } else if (!data.hasOwnProperty("accountType")) {
              data.accountType = `QR${qrReadData.origenQr}`;
            }
            data.idQr = qrReadData.idQr;
            data.codComercio = qrReadData.codComercio;
            data.cardData = {
              EMISOR: qrReadData.codEf,
              NUMERO_TARJETA: qrReadData.cuentaAbono,
              DOCUMENTO: qrReadData.ciNitDestino,
              NOMBRE_USUARIO: qrReadData.titularDestino,
              TAR_MASK: '',
              MONTO: importeQR,
              datosAdicionales: []
            };
            data.tipoQr = qrReadData.tipoQr;
            data.origenQr = qrReadData.origenQr;
            data.cuotas = qrReadData.cuotas;
            data.codOperacion = qrReadData.codOperacion;
            data.codDestinatario = qrReadData.codDestinatario;
            data.idOperacionCaja = qrReadData.idOperacionCaja;
            // Si se ha enviado un codigo de Comercio dentro de las propiedas leidas del QR
            if (qrReadData.codComercio !== null && qrReadData.codComercio !== '') {
              data.accountType = 'QRCOMERCIO';
              data.accountNumber = qrReadData.codComercio;
              data.cardData.NUMERO_TARJETA = qrReadData.codComercio;
              if (qrReadData.origenQr !== 'BANCARD') {
                data.idTransaccionQr = qrReadData.idTransaccionQr;
              }
            }
            if (data.origenQr === 'BANCARDATM') {
              data.timestamp = qrReadData.timestamp;
              data.orgId = qrReadData.orgId;
              data.terminalDsc = qrReadData.terminalDsc;
              data.terminalId = qrReadData.terminalId;
            }

            let pathQr = '/app/leer/qr/origen';

            if (data.accountType === 'QRDIMOPROMOCION') {
              pathQr = '/app/promociones/qr';
            }
            history.push({
              pathname: pathQr,
              state: {
                destinationData: data,
                titleName: data.codOperacion,
              }
            });
          })
          .catch((response) => {
            setLoading(false);
            enqueueSnackbar(response.error ? response.error.message
              : 'No se pudo leer el Codigo QR. Favor vuelva a intentarlo mas tarde', {
              variant: 'error'
            });
            // console.log('Error: ' + response);
          });
      } catch (error) {
        GAException(error);
        isLoading(false);
        enqueueSnackbar('Something went wrong', {
          variant: 'error'
        });
      } finally {

      }
    },
    [],
  );
  const handleOnclick = () => {
    try {
      if(user.flagUserActivate === false || user.flagUsuarioValidadoATC === false){
        enqueueSnackbar("Para acceder a esta opción, debes completar tu registro.", {
          variant: 'info'
        });
        return;
      }
      let funcionalidadKey = "";

      if (href === "/app/cooperativas") {
        funcionalidadKey = funcionalidades.COOP.key;
      } else if (href === "/app/leer/qr") {
        funcionalidadKey = funcionalidades.QRCOMPRAS.key;
      } else if (href === '/app/recargasaldo') {
        funcionalidadKey = funcionalidades.RECARGASALDO.key;
      }

      if (funcionalidadKey !== "") {
        setLoading(true);
        functionalitiesService.funcionalidadHabilitada({ codigo: funcionalidadKey })
          .then((response) => {
            setLoading(false);
            if (response === true) {
              // if ((isMobile || isMobileIos) && funcionalidadKey === 'QRCOMPRAS') {
              if ((window.ReactNativeWebView) && funcionalidadKey === 'QRCOMPRAS') {
                try {
                  setIsloadingEventListener(true);
                  //pasar a react native el evento y que abra la camara y lea el qr
                  window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'readQr' }));
                } catch (e) {
                }
                //RECIBIR EL EVENTO DE REACT NATIVE SI ES ANDROID
                if (isMobile) {
                  document.addEventListener("message", function (event) {
                    readQrfunction(event.data);
                  });
                }
                //RECIBIR EL EVENTO DE REACT NATIVE SI ES IOS
                if (isMobileIos) {
                  window.addEventListener("message", function (event) {
                    readQrfunction(event.data);
                  });
                }

              } else {
                history.push(href);
              }
            } else {
              enqueueSnackbar(msgOpcionNoDisponible, {
                variant: 'info'
              });
            }
          })
          .catch(() => {
            setLoading(false);
            enqueueSnackbar(msgOpcionNoDisponible, {
              variant: 'info'
            });
          });
      } else {
        history.push(href);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(msgOpcionNoDisponible, {
        variant: 'info'
      });
    }
  };

  if (isLoading) {
    return <SplashScreen />
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          //component={onLogoutClick ? Button : RouterLink}
          //onClick={onLogoutClick ? onLogoutClick : ''}
          //exact
          //to={href}
          onClick={onLogoutClick ? onLogoutClick : handleOnclick}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
        </Button>
        <Typography
          className={classes.iconLabel}
          variant="h6"
        >
          {title}
        </Typography>
      </Box>
    </ListItem>
  );
}

BottomItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  endIcon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string
};

BottomItem.defaultProps = {
  open: false
};

export default BottomItem;
