import React from 'react';

import GAException from 'src/components/GAException';
import { Box } from 'react-feather';

function Logo(props) {
  return (
    <img
      style={{ width: '220px' }}
      alt="Logo"
      src={"/static/images/dimo/logo_dimo_white.png"}
      {...props}
    />
  );
}

export function LogoResize(props) {
  return (
    <img
      style={props.width ? { width: props.width } : { width: '220px' }}
      alt="Logo"
      src={"/static/images/dimo/logo_dimo_white.png"}
      {...props}
    />
  );
}

export function LogoBanderaPya({ tamano, classes }){
  return (
    <img
      src="/static/images/dimo/bandera_paraguaya.png"
      alt="banderaParaguaya"
      height={tamano}
      width={tamano}
      className={classes}
      style={{ marginRight: '5px' }}
    />
  );
}

export function LogoMin(props) {
  return (
    <img
      style={{ width: '50px' }}
      alt="Logo"
      src={"/static/logo_dimo_blue.jpg"}
      {...props}
    />
  );
}

export function LogoBlue(props) {
  return (
    <img
      style={{ width: '100%' }}
      alt="Logo"
      src={"/static/images/dimo/logo_dimo_blue.png"}
      {...props}
    />
  );
}

export function LogoCabal(props) {
  return (
    <img
      style={{ width: '50px' }}
      alt="Logo"
      src={"/static/logo_cabal_vertical.jpg"}
      {...props}
    />
  );
}

export function LogoCabalResize(props) {
  return (
    <img
      className={props.className}
      alt="Logo"
      src={"/static/logo_cabal_vertical.jpg"}
    />
  );
}

export function LogoCabalHorizontal(props) {
  return (
    <img
      style={{ width: '100%' }}
      alt="Logo"
      src={"https://estatico.cabal.coop.py/public/institucional/logos/logo.png"}
      {...props}
    />
  );
}

export function LogoCoop({ logoUrl, ...props }) {
  return (
    <img
      style={{ width: '100%' }}
      alt="Logo"
      src={(logoUrl && logoUrl !== null) ? logoUrl : ""}
      {...props}
    />
  );
}

export function LogoCabalPanal(props) {
  return (
    <img
      style={{ width: '70%', maxWidth:'250px' }}
      alt="Logo"
      src={"https://estatico.cabal.coop.py/public/dimo/cabal_panal.png"}
      {...props}
    />
  );
}

export default Logo;
