import React, { useEffect } from 'react';

import GAException from 'src/components/GAException';
import { useLocation, matchPath } from 'react-router';

import PropTypes from 'prop-types';

import {
  Box,
  List,
  makeStyles
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'src/actions/accountActions';

import BottomItem from './BottomItem';

import { Home, RefillMinute, QR } from './icons';

const navConfig = [
  {
    //subheader: 'Menu DIMO',
    items: [
      {
        icon: QR,
        href: '/app/leer/qr',
        title: 'Escanear QR'
      },
      {
        icon: Home,
        href: '/app',
        title: 'Inicio'
      },
      {
        icon: RefillMinute,
        href: '/app/recargasaldo',
        title: 'Recarga de Saldo'
      },
    ]
  },
];

function renderBottomItems(classes, { items, ...rest }) {
  return (
    <List
      className={classes.list}
    >
      {items.reduce(
        (acc, item, index) => reduceChildRoutes({ index, acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  index,
  acc,
  pathname,
  item,
  depth = 0,
  logout
}) {
  const key = item.title + '-' + index;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <BottomItem
        depth={depth}
        icon={item.icon}
        key={key + 'Bottom-Item'}
      >
        {renderBottomItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </BottomItem>
    );
  } else {
    acc.push(
      <BottomItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        endIcon={item.endIcon}
        key={key + 'Bottom-Item'}
        info={item.info}
        title={item.title}
        onLogoutClick={item.logout ? logout : null}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  mobileDrawer: theme.menuView.mobileDrawer,
  desktopDrawer: theme.menuView.desktopDrawer,
  avatar: theme.menuView.avatar,
  listSubheader: {
    color: '#FFF',
    padding: '0'
  },
  link: {
    color: '#FFF'
  },
  logo: {
    width: 153,
    paddingTop: 30,
    '& img': {
      width: '100%!important',
    }
  },
  list:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      history.push('/login');
    } catch (error) {
                  GAException(error);

    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      className={classes.root}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {navConfig.map((config, i) => (
        <List
          key={i + '-BottomBar-List'}
          style={{
            padding:'0'
          }}
        >
          {renderBottomItems(classes, { items: config.items, pathname: location.pathname, account: account, logout: () => handleLogout() })}
        </List>
      ))}
    </Box>
  );

  return (
    <>
      {content}
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
