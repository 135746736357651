/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SEGUIMIENTO_AUMENTO_LINEA_REQUEST,
  SEGUIMIENTO_AUMENTO_LINEA_SUCCESS,
  SEGUIMIENTO_AUMENTO_LINEA_FAILURE,
  CONSULTA_EXTRACTO_REQUEST,
  CONSULTA_EXTRACTO_SUCCESS,
  CONSULTA_EXTRACTO_FAILURE,
  DESCARGAR_EXTRACTO_REQUEST,
  DESCARGAR_EXTRACTO_SUCCESS,
  DESCARGAR_EXTRACTO_FAILURE,
  TARJETAS_CREDITO_REQUEST,
  TARJETAS_CREDITO_SUCCESS,
  TARJETAS_CREDITO_FAILURE,
  SEGUIMIENTO_REIMPRESION_T_C_REQUEST,
  SEGUIMIENTO_REIMPRESION_T_C_SUCCESS,
  SEGUIMIENTO_REIMPRESION_T_C_FAILURE,
  SEGUIMIENTO_CAMBIO_CICLO_T_C_REQUEST,
  SEGUIMIENTO_CAMBIO_CICLO_T_C_SUCCESS,
  SEGUIMIENTO_CAMBIO_CICLO_T_C_FAILURE,
  SEGUIMIENTO_ADICIONAL_T_C_REQUEST,
  SEGUIMIENTO_ADICIONAL_T_C_SUCCESS,
  SEGUIMIENTO_ADICIONAL_T_C_FAILURE,
  REIMPRESION_T_C_REQUEST,
  REIMPRESION_T_C_SUCCESS,
  REIMPRESION_T_C_FAILURE,
  MOVIMIENTOS_T_C_REQUEST,
  MOVIMIENTOS_T_C_SUCCESS,
  MOVIMIENTOS_T_C_FAILURE,
  CONSULTA_SALDO_T_C_REQUEST,
  CONSULTA_SALDO_T_C_SUCCESS,
  CONSULTA_SALDO_T_C_FAILURE,
  CONSULTA_SALDO_ADICIONAL_T_C_REQUEST,
  CONSULTA_SALDO_ADICIONAL_T_C_SUCCESS,
  CONSULTA_SALDO_ADICIONAL_T_C_FAILURE,
  CAMBIO_CICLO_T_C_REQUEST,
  CAMBIO_CICLO_T_C_SUCCESS,
  CAMBIO_CICLO_T_C_FAILURE,
  BLOQUEO_DESBLOQUEO_T_C_REQUEST,
  BLOQUEO_DESBLOQUEO_T_C_SUCCESS,
  BLOQUEO_DESBLOQUEO_T_C_FAILURE,
  AVISO_VIAJE_T_C_REQUEST,
  AVISO_VIAJE_T_C_SUCCESS,
  AVISO_VIAJE_T_C_FAILURE,
  AUMENTO_LINEA_T_C_REQUEST,
  AUMENTO_LINEA_T_C_SUCCESS,
  AUMENTO_LINEA_T_C_FAILURE,
  ADICIONAL_T_C_REQUEST,
  ADICIONAL_T_C_SUCCESS,
  ADICIONAL_T_C_FAILURE
} from 'src/actions/creditCardActions';

const initialState = {
  user: null
};


const creditCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADICIONAL_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case ADICIONAL_T_C_SUCCESS: {
      const { adicionalTC } = action.payload;

      return produce(state, (draft) => {
        draft.adicionalTC = adicionalTC;
      });
    }

    case ADICIONAL_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case AUMENTO_LINEA_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case AUMENTO_LINEA_T_C_SUCCESS: {
      const { aumentoLineaTC } = action.payload;

      return produce(state, (draft) => {
        draft.aumentoLineaTC = aumentoLineaTC;
      });
    }

    case AUMENTO_LINEA_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case AVISO_VIAJE_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case AVISO_VIAJE_T_C_SUCCESS: {
      const { avisoViajeTC } = action.payload;

      return produce(state, (draft) => {
        draft.avisoViajeTC = avisoViajeTC;
      });
    }

    case AVISO_VIAJE_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case BLOQUEO_DESBLOQUEO_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case BLOQUEO_DESBLOQUEO_T_C_SUCCESS: {
      const { bloqueoDesbloqueoTC } = action.payload;

      return produce(state, (draft) => {
        draft.bloqueoDesbloqueoTC = bloqueoDesbloqueoTC;
      });
    }

    case BLOQUEO_DESBLOQUEO_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CAMBIO_CICLO_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CAMBIO_CICLO_T_C_SUCCESS: {
      const { cambioCicloTC } = action.payload;

      return produce(state, (draft) => {
        draft.cambioCicloTC = cambioCicloTC;
      });
    }

    case CAMBIO_CICLO_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CONSULTA_EXTRACTO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTA_EXTRACTO_SUCCESS: {
      const { consultaExtracto } = action.payload;

      return produce(state, (draft) => {
        draft.consultaExtracto = consultaExtracto;
      });
    }

    case CONSULTA_EXTRACTO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CONSULTA_SALDO_ADICIONAL_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTA_SALDO_ADICIONAL_T_C_SUCCESS: {
      const { consultaSaldoAdicionalTC } = action.payload;

      return produce(state, (draft) => {
        draft.consultaSaldoAdicionalTC = consultaSaldoAdicionalTC;
      });
    }

    case CONSULTA_SALDO_ADICIONAL_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case CONSULTA_SALDO_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case CONSULTA_SALDO_T_C_SUCCESS: {
      const { consultaSaldoTC } = action.payload;

      return produce(state, (draft) => {
        draft.consultaSaldoTC = consultaSaldoTC;
      });
    }

    case CONSULTA_SALDO_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case DESCARGAR_EXTRACTO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case DESCARGAR_EXTRACTO_SUCCESS: {
      const { descargarExtracto } = action.payload;

      return produce(state, (draft) => {
        draft.descargarExtracto = descargarExtracto;
      });
    }

    case DESCARGAR_EXTRACTO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case MOVIMIENTOS_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case MOVIMIENTOS_T_C_SUCCESS: {
      const { movimientosTC } = action.payload;

      return produce(state, (draft) => {
        draft.movimientosTC = movimientosTC;
      });
    }

    case MOVIMIENTOS_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case REIMPRESION_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case REIMPRESION_T_C_SUCCESS: {
      const { reimpresionTC } = action.payload;

      return produce(state, (draft) => {
        draft.reimpresionTC = reimpresionTC;
      });
    }

    case REIMPRESION_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_ADICIONAL_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_ADICIONAL_T_C_SUCCESS: {
      const { seguimientoAdicionalTC } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoAdicionalTC = seguimientoAdicionalTC;
      });
    }

    case SEGUIMIENTO_ADICIONAL_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_AUMENTO_LINEA_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_AUMENTO_LINEA_SUCCESS: {
      const { seguimientoAumentoLinea } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoAumentoLinea = seguimientoAumentoLinea;
      });
    }

    case SEGUIMIENTO_AUMENTO_LINEA_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_CAMBIO_CICLO_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_CAMBIO_CICLO_T_C_SUCCESS: {
      const { seguimientoCambioCicloTC } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoCambioCicloTC = seguimientoCambioCicloTC;
      });
    }

    case SEGUIMIENTO_CAMBIO_CICLO_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SEGUIMIENTO_REIMPRESION_T_C_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SEGUIMIENTO_REIMPRESION_T_C_SUCCESS: {
      const { seguimientoReimpresionTC } = action.payload;

      return produce(state, (draft) => {
        draft.seguimientoReimpresionTC = seguimientoReimpresionTC;
      });
    }

    case SEGUIMIENTO_REIMPRESION_T_C_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }
    
    case TARJETAS_CREDITO_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case TARJETAS_CREDITO_SUCCESS: {
      const { tarjetasCredito } = action.payload;

      return produce(state, (draft) => {
        draft.tarjetasCredito = tarjetasCredito;
      });
    }

    case TARJETAS_CREDITO_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    default: {
      return state;
    }
  }
};

export default creditCardReducer;
