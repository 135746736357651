import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import GAException from 'src/components/GAException';
import TokenService from './TokenService'
import { auth_service_api_url as API_URL } from 'src/services/backendApiService';
import accountService from './accountService';
import {getData as getDataActividad} from 'src/hooks/useRegistrarActividadDimo';

const axios_dummy = false;

const API_REQUEST_HEADER = {
  appVersion: "1.0.2",
  token: "prueba",
  user: "csoto"
}

const API_REQUEST_PHONE_DATA = {
  codigo: "lkajsdlkjasd",
  imei: "a1s6d165s1d6asd",
  modelo: "Iphone 11",
  sistemaOperativo: "IOS11"
}

const CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
};

const HEADERS_WITH_TOKEN = () => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + TokenService.getLocalAccessToken()
  }
  return headers;
}

export const toUserLoginData = (userReduxData) => {

  let userData = {
    id: userReduxData.id,
    avatar: userReduxData.avatar,
    email: userReduxData.email,
    username: userReduxData.username, // nro documento
    document: userReduxData.username,
    firstName: userReduxData.firstName,
    lastName: userReduxData.lastName,
    phone: userReduxData.phone,
    flagTermsAndConditions: userReduxData.flagTermsAndConditions,
    flagOtpActivate: userReduxData.flagOtpActivate,
    flagEmailActivate: userReduxData.flagEmailActivate,
    flagUserActivate: userReduxData.flagUserActivate,
    flagRegisterLevel2: userReduxData.flagRegisterLevel2,
    prepaidCard: [],
    creditCards: [],
    selfie: userReduxData.selfie,
    formatImg: userReduxData.formatImg,
    birthDate: userReduxData.birthDate,
    flagPrimerInicioSesionDia: userReduxData.flagPrimerInicioSesionDia,
    flagUsuarioMiCabal: userReduxData.flagUsuarioMiCabal,
    flagUsuarioValidadoATC: userReduxData.flagUsuarioValidadoATC,
    codPromotor: (userReduxData.codPromotor+""),
  }

  return userData;
}

const HEADERS = () => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + TokenService.getLocalAccessToken()
  }
  return headers;
}



const myInterval = setInterval(() => {
  const renewToken = localStorage.getItem('renewToken');
  if (renewToken > 0) {
    localStorage.setItem('renewToken', renewToken - 5);
  }
}, 5000);

axios.interceptors.request.use(
  config => {
    const token = TokenService.getLocalAccessToken();
    if (token && config.headers.Authorization && !config.url.includes('/ws-user-kyc') && !config.url.includes('/kyc')) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
)

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    // axios.interceptors.response.use(
    //   async (response) => {
    //     const renewToken = localStorage.getItem('renewToken');
    //     const originalConfig = response.config;
    //     if (!originalConfig.url.includes('inicioSesion')
    //       && !originalConfig.url.includes('/v1/validarToken')
    //       && !originalConfig.url.includes('/cerrarSesion')
    //       && !originalConfig.url.includes('/verificarUsuario')
    //       && !originalConfig.url.includes('/registrarActividadFrontend')
    //       && renewToken
    //       && renewToken === '0') {
    //       localStorage.setItem('renewToken', 25);
    //       await axios.post(
    //         `${API_URL}/v1/refreshToken`, {}, {
    //           headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
    //           }
    //         }
    //       )
    //         // eslint-disable-next-line consistent-return
    //         .then((res) => {
    //           if (res.data.header.codReturn === '0') {
    //             localStorage.setItem('renewToken', 25);
    //             if (res.data.data) {
    //               console.log(`Se actualiza el tocken -> ${res.data.data.token}`);
    //               TokenService.updateLocalAccessToken(res.data.data.token);
    //             }
    //           }
    //         })
    //         .catch((error) => {
    //           localStorage.setItem('renewToken', 0);
    //           GAException(error);
    //         });
    //     }
    //     return response;
    //   },
    //   (error) => {
    //     if (error.response && error.response.status === 401) {
    //       if (error.config.headers.Authorization !== TokenService.getLocalAccessToken()) {
    //         return error.config;
    //       }
    //       this.setSession(null);
    //
    //       if (onLogout) {
    //         onLogout();
    //       }
    //     }
    //
    //     return Promise.reject(error);
    //   }
    // );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  login = (data) => new Promise((resolve, reject) => {
    axios.post(
      API_URL + '/inicioSesion',
      data,
      CONFIG
    )
      .then((response) => {

        if (response.data.header.codReturn === '0') {
          let dataAutenticacion = data.loginRequest;
          //encryptar el body y setearlo en el localstorage
          let dataEncriptada = btoa(JSON.stringify(dataAutenticacion));

          localStorage.setItem('dataAutenticacion', JSON.stringify(dataEncriptada));

          this.setSession(response.data.data.authToken);

          const dataResponse = response.data.data;

          let user = {
            id: '5e86809283e28b96d2d38537',
            avatar: '/static/images/avatars/avatar_6.png',
            email: dataResponse.email,
            username: data.loginRequest.user, // nro documento
            firstName: dataResponse.nombres,
            lastName: dataResponse.apellidos,
            passFidUser: dataResponse.passFidUser,
            phone: dataResponse.telefono,
            flagTermsAndConditions: dataResponse.flagAceptaTerminos,
            flagOtpActivate: dataResponse.flagUsuarioActivadoOtp,
            flagEmailActivate: dataResponse.flagUsuarioActivadoEmail,
            flagUserActivate: dataResponse.flagUsuarioValidado,
            flagRegisterLevel2: dataResponse.flagUsuarioCatastroNivel2,
            selfie: dataResponse.selfie,
            formatImg: dataResponse.formato,
            birthDate: dataResponse.hasOwnProperty("fechaNacimiento") ? dataResponse.fechaNacimiento : "",
            flagPrimerInicioSesionDia: dataResponse.flagPrimerInicioSesionDia,
            flagUsuarioMiCabal: (dataResponse.hasOwnProperty("flagUsuarioMiCabal") && dataResponse.flagUsuarioMiCabal === true ) ? true : false,
            flagUsuarioValidadoATC: (dataResponse.hasOwnProperty("flagUsuarioValidadoATC") && dataResponse.flagUsuarioValidadoATC === true ) ? true : false,
            codPromotor: dataResponse.hasOwnProperty("codPromotor") ? (dataResponse.codPromotor+"") : "",
          }
          this.setSessionTimerOn();
          localStorage.setItem('userLogued', JSON.stringify({ authToken: response.data.data.authToken, user: user }));
          localStorage.setItem('renewToken', 25);
          resolve(user);
        }
        else {
          reject(response.data.header);
        }

      })
      .catch((error) => {
                      //GAException(error);
        //console.log(error);
        reject(error);
      });
  })

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {

    if (!axios_dummy) {

      let data = {
        requestHeaderApp: API_REQUEST_HEADER,
        phoneData: API_REQUEST_PHONE_DATA,
        loginRequest: {
          user: (email+"").trim(),
          password: password
        }
      }

      axios.post(
        API_URL + '/verificarUsuario',
        data,
        CONFIG
      )
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            this.login(data)
            .then((response) => {
              accountService.registrarActividadFrontend("Login","/login", getDataActividad());
              resolve(response);
            })
            .catch(error => reject(error));
          }
          else {
            ////console.log('response.data.header', response.data.header);
            //reject(response.data.header);
            reject({codReturn: "9050", txtReturn: "El usuario no esta registrado a DIMO, Registrate."});
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });

    }
    else {
      axios.post('/api/account/login', { email, password: 'admin' })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.accessToken);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
  })

  verificarSesion = ()  => new Promise((resolve, reject) => {

    if (!axios_dummy) {

      axios.post(
        API_URL + '/v1/validarToken', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
          }
        }
      )
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          if (response.data.data.resultado === "true") {
            //Sesion valida
            resolve(true);
          }
          else {
            reject(false);
          }
        }
        else {
          reject(false);
        }
      })
      .catch((error) => {
        reject(false);
      });
    }
  });

  loginInWithToken = () => new Promise((resolve, reject) => {
    /*axios.get('/api/account/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
    */
    try {

      const userLogued = JSON.parse(localStorage.getItem('userLogued'));
      const accessToken = localStorage.getItem('accessToken');

      if (userLogued !== null) {

        const authToken = userLogued.authToken;
        const user = userLogued.user;

        if (authToken === accessToken) {
          this.validarSesionDimo();
          this.setSessionTimerOn();
          resolve(user);
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }

  })

  logout = () => {
    this.cerrarSesion();

    this.setSession(null);
    localStorage.setItem('accessToken', '');
    localStorage.removeItem('userLogued');

    if (this.isSesionExpired()) {
      localStorage.removeItem("loggedOut");
    } else {
      localStorage.setItem("loggedOut", "1");
      localStorage.removeItem("sesionExpired");
    }
    localStorage.removeItem('renewToken');
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  setRegisterSession = () => {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    axios.defaults.headers.common.Authorization = `Bearer ${uuid}`;

    console.log('axios', axios.defaults.headers.common);

  }

  deleteRegisterSession = () => {
    delete axios.defaults.headers.common.Authorization;
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()

  isUserValidated = () => {
    try {

      const userLogued = JSON.parse(localStorage.getItem('userLogued'));

      if (userLogued !== null) {

        const validUser = userLogued.validatedUser ? true : false;

        return validUser;

      } else {
        return false;
      }
    } catch (error) {

      return false;
    }
  }

  isUserValidatedATC = () => {
    try {

      const userLogued = toUserLoginData(JSON.parse(localStorage.getItem('userLogued')).user);

      if (userLogued !== null) {

        const validUserATC = userLogued.flagUsuarioValidadoATC === true ? true : false;

        return validUserATC;

      } else {
        return false;
      }
    } catch (error) {

      return false;
    }
  }

  forgotPassword = (request) => new Promise((resolve, reject) => {

    const dataPrm = {
      user: request.documento.trim(),
    }

    try {
      axios.post(API_URL + '/v1/recuperarContrasena', { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, recoveryDimoRequest: dataPrm })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
    catch (ee) {
      //console.log(ee);
    }
  });

  forgotPasswordV2 = (request) => new Promise((resolve, reject) => {

    const dataPrm = {
      user: request.hasOwnProperty("documento") ? request.documento : "",
      recoveryMethod: request.hasOwnProperty("recoveryMethod") ? request.recoveryMethod : ""
    }

    try {
      axios.post(API_URL + '/v2/recuperarContrasena', { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, recoveryDimoRequest: dataPrm })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
    catch (ee) {
      //console.log(ee);
    }
  });

  restorePassword = (request) => new Promise((resolve, reject) => {

    const dataPrm = {
      token: request.token,
      password: request.password,
    }

    try {
      axios.post(API_URL + '/v1/procesarRecuperarContrasena', { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, processRecoveryDimoRequest: dataPrm })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
    catch (ee) {
      //console.log(ee);
    }
  });

  restorePasswordWithOTP = (request) => new Promise((resolve, reject) => {

    const dataPrm = {
      token: request.token,
      password: request.password,
      otp: request.hasOwnProperty("otp") ? request.otp : "",
    }

    try {
      axios.post(API_URL + '/v2/procesarRecuperarContrasena', { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, processRecoveryDimoRequest: dataPrm })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
    catch (ee) {
      //console.log(ee);
    }
  });

  changePassword = (request) => new Promise((resolve, reject) => {

    const dataPrm = {
      oldPassword: request.hasOwnProperty("oldPassword") ? request.oldPassword : "",
      password: request.hasOwnProperty("password") ? request.password : "",
    }

    try {
      axios.post(API_URL + '/v1/procesarModificarContrasena', { requestHeaderApp: API_REQUEST_HEADER, phoneData: API_REQUEST_PHONE_DATA, processRecoveryDimoRequest: dataPrm }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
        .then((response) => {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        })
        .catch((error) => {
                      //GAException(error);
          reject(error);
        });
    }
    catch (ee) {
      //console.log(ee);
    }
  });

  cerrarSesion = () => new Promise((resolve, reject) => {
    try {
      if (this.getAccessToken() !== null && this.getAccessToken() !== '') {
        axios.post(API_URL + '/cerrarSesion', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        })
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject();
        });
      }
      else {
        resolve();
      }
    }
    catch (ee) {
      //console.log("error", ee);
      reject();
    }
  });

  getUserData = () => {
    try {

      const userLogued = JSON.parse(localStorage.getItem('userLogued'));

      if (userLogued !== null) {
        const useData = toUserLoginData(userLogued.hasOwnProperty("user") ? userLogued.user : {});

        return useData;

      } else {
        return null;
      }
    } catch (error) {

      return null;
    }
  }


  validarSesionDimo = (clearIntervalFunction) => {
    this.verificarSesion()
    .then(()=> {
      // Si el token de la sesion esta aun vigente,
      // pero se cerro sesion lanzamos una excepcion para tomar la verificacion como fallida y desactive el timer
      if (this.getAccessToken() === null || this.getAccessToken() === "") {
        throw "Logged out"
      }
    })
    .catch((e) =>{
      // clearInterval(timerId);
      if (clearIntervalFunction) {
        clearIntervalFunction();
      }
      this.setSessionExpired();
      this.setSession(null);
      this.logout();

      // Solo redireccionamos al Login si es que esta dentro de la aplicacion
      if (window.location.pathname.includes('/app')) {
        window.location.reload();
      }
    });
  }

  setSessionTimerOn = () => {
    let timerId = setInterval(() => {
      this.validarSesionDimo( ()=> clearInterval(timerId));
    }, 10000); // 10 segundos
  }

  setSessionExpired = () => {

    const loggedOut = localStorage.getItem("loggedOut") === "1" ? true : false;

    if (!loggedOut) {
      localStorage.setItem("sesionExpired", "1");
      localStorage.removeItem("loggedOut");
    }

  }

  isSesionExpired = ()=> {
    let retorno = false;

    const sesionExpired = localStorage.getItem("sesionExpired") === "1";

    if (sesionExpired) {
      retorno = true;

    }

    return retorno;
  }

  datosSesionUsuario = (data) => new Promise((resolve, reject) => {
    axios.post(
      API_URL + '/v1/datosInicioSesion',
      data,
      CONFIG
    )
      .then((response) => {

        if (response.data.header.codReturn === '0') {
          let userData = this.getUserData();
          const dataResponse = response.data.data;

          userData.flagUsuarioValidadoATC = (dataResponse.hasOwnProperty("flagUsuarioValidadoATC") && dataResponse.flagUsuarioValidadoATC === true ) ? true : false;

          localStorage.setItem('userLogued', JSON.stringify({ authToken: localStorage.getItem('accessToken'), user: userData }));
          resolve(userData);
        }
        else {
          reject(response.data.header);
        }

      })
      .catch((error) => {
                      //GAException(error);
        //console.log(error);
        reject(error);
      });
  })


}

const authService = new AuthService();

export default authService;
