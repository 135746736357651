import locationService from 'src/services/locationService';

export const PAISES_REQUEST = '@account/paises-request';
export const PAISES_SUCCESS = '@account/paises-request';
export const PAISES_FAILURE = '@account/paises-request';

export const CIUDADES_REQUEST = '@account/ciudades-request';
export const CIUDADES_SUCCESS = '@account/ciudades-request';
export const CIUDADES_FAILURE = '@account/ciudades-request';

export function paises(paisesReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: PAISES_REQUEST });

      const paises = await locationService.paises(paisesReq);

      dispatch({
        type: PAISES_SUCCESS,
        payload: {
          paises
        }
      });
    } catch (error) {
      dispatch({ type: PAISES_FAILURE });
      throw error;
    }
  };
}

export function ciudades(ciudadesReq) {
  return async (dispatch) => {
    try {
      dispatch({ type: CIUDADES_REQUEST });

      const ciudades = await locationService.ciudades(ciudadesReq);

      dispatch({
        type: CIUDADES_SUCCESS,
        payload: {
          ciudades
        }
      });
    } catch (error) {
      dispatch({ type: CIUDADES_FAILURE });
      throw error;
    }
  };
}
