import {useState, useEffect} from "react";
import moment from "moment"; // <-- new

export function checkForAndroid() {

    const ua = window.navigator.userAgent;
    const isAndroid = !!ua.match(/Android/i);

    const isFromWebView = ua.includes('wv');

    //console.clear();

    //console.log("isFromWebView", isFromWebView)

    return isAndroid && !isFromWebView;
}

export function checkForAndroidInstall() {
    
    if (navigator.standalone) {
        console.log("navStandalone");
        return false;
    }

    const isAndroid = checkForAndroid();
    //return true;

    const today = moment().toDate();
    const lastPrompt = moment(localStorage.getItem("installDIMOAppPrompt"));
    const days = moment(today).diff(lastPrompt, "days");

    const prompt = (isNaN(days) || days >= 1) && isAndroid;
    
    if (prompt) {
        
        if (prompt && "localStorage" in window) {
            localStorage.setItem("installDIMOAppPrompt", today);
        }
    }

    return prompt;
}

export function useIsAndroidInstallApp() {
    const [isAndroid, setIsAndroid] = useState(false);
    
    useEffect(() => {
        setIsAndroid(checkForAndroidInstall());
        return() => console.log("CLEANUP INSTALL PROMPT", isAndroid);
    }, []);

    return isAndroid;
}

export default useIsAndroidInstallApp;
