import axios from '../utils/axios';
import GAException from 'src/components/GAException';

const API_URL = 'http://localhost:7095/api/ceibo/tarjetaPrepaga/v1';

const HEADER = {
  idSesion: "102",
  idRequest: "123456",
  codigoEF: "csoto",
  canal: "102",
  idClienteEF: "prueba",
  ciCliente: "csoto"
}

class EntityService {
  /*setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };*/

  consultaEmisorTarjeta = (ConsultaEmisorTarjeta) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/ConsultaEmisorTarjeta', { header: HEADER, data: ConsultaEmisorTarjeta })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

  obtenerCierreEmisor = (ObtenerCierreEmisor) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/ObtenerCierreEmisor', { header: HEADER, data: ObtenerCierreEmisor })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
                      //GAException(error);
        reject(error);
      });
  })

}

const entityService = new EntityService();

export default entityService;
